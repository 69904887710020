import cancelOrder from "./cancelOrder.api";
import changeStatus from "./changeStatus.api";
import confirmShipping from "./confirmShipping.api";
import getOrderDetails from "./getOrderDetails.api";
import getOrders from "./getOrders.api";
import getUsers from "./getUsers.api";
import getSubscriptions from "./getSubscriptions.api";
import updateShipping from "./updateShipping.api";
import getUserDetails from "./getUserDetails.api";
import changeSubscription from "./changeSubscription.api";
import cancelSubscription from "./cancelSubscription.api";
import addCard from "./addCard.api";
import updateCard from "./updateCard.api";
import addOrder from "./addOrder.api";

const adminApi = {
  getOrders,
  getUsers,
  getUserDetails,
  changeSubscription,
  getOrderDetails,
  confirmShipping,
  updateShipping,
  changeStatus,
  cancelOrder,
  cancelSubscription,
  getSubscriptions,
  addCard,
  updateCard,
  addOrder,
};

export default adminApi;