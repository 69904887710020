import axios from "axios";

const baseURL = process.env.REACT_APP_ADMIN_HOST_URL;

const getUserDetails = (uuid: string): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  const params = { user_uuid: uuid };
  const headers = { Authorization: `Bearer ${user.access_token}` };

  return new Promise((resolve, reject) => {
    axios({ url: "user/details", baseURL, params, headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default getUserDetails;