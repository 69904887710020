
import './App.css';
import { Navigate, Route, useLocation,Routes  } from "react-router-dom"
import SignIn from "../src/modules/auth/sign-in";
import MainLayout from "../src/components/layout/main.layout";
import AdminLayout from "../src/components/layout/admin.layout";
import Dashboard from './modules/dashboard';
import Order from './modules/order';
import Subscriptions from './modules/subscriptions';
import Products from './modules/products';
import EditProductsComponent from './modules/products/pages/editProduct';
import OrderDetailsComponent from './modules/order/pages/orderDetails';
import * as routes from "./lib/constants/routes.js";
import Users from './modules/users/index.js';
import ViewUserComponent from './modules/users/pages/viewUser.tsx';
import OrderCreateComponent from './modules/order/pages/orderCreate.tsx';
var authed = false;

function RequireAuth({ children }) {
  const location = useLocation();

  if (localStorage.getItem('user') !== null) { 
    const user = JSON.parse(localStorage.getItem('user'));
    if(!user?.access_token){
      return <Navigate to={routes.SIGN_IN} replace state={{ path: location.pathname }} />
    }else{
      authed = true;
    }
  }else{
    return <Navigate to={routes.SIGN_IN} replace state={{ path: location.pathname }} />
  }
  return authed === true ? 
  (children) : 
  (<Navigate to={routes.SIGN_IN} replace state={{ path: location.pathname }} />);
}

function App() {
  return (
    <Routes>
      <Route path="" element={<MainLayout/>} >
        <Route path="" element={<Navigate to="/signin" />} />
        <Route path="/signin" element={<SignIn/> } />
        <Route path="/portal" element={<RequireAuth><AdminLayout/></RequireAuth>}>
          <Route path="*" element={<Dashboard/>}/>
          <Route path="orders">
            <Route path="" element={<Order />} />
            <Route path="add" element={<OrderCreateComponent />} />
            <Route path=":uuid" element={<OrderDetailsComponent />} />
          </Route>
          <Route path="subscriptions" element={<Subscriptions />} />
          <Route path="products">
            <Route path="" element={<Products />} />
            <Route path="create" element={<EditProductsComponent products={[]} />} />
            <Route path=":uuid/edit" element={<EditProductsComponent products={[]} />} />
          </Route>
          <Route path="users">
            <Route path="" element={<Users />} />
            <Route path=":uuid" element={<ViewUserComponent />} />
          </Route>
        </Route>
      </Route>
      {/* Other routes */}

    </Routes>
  );
}

export default App;
