import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, FormControl, InputLabel, MenuItem, Select, Box } from '@mui/material';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import adminApi from '../../../app/api/admin';

interface ChangeStatusDialogProps {
  open: boolean;
  selectedOrder: any;
  handleClose: () => void;
  postSubmit: (order_status: number, payment_status: number) => void;
}

const ChangeStatusDialog: React.FC<ChangeStatusDialogProps> = ({
  open,
  selectedOrder,
  handleClose,
  postSubmit,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: '25px', minWidth: '400px' } }}>
      <DialogTitle sx={{ fontSize: '31px', fontWeight: 500, textAlign: 'center' }}>Change Status</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            order_status: selectedOrder?.status ?? 0,
            payment_status: selectedOrder?.payment_status ?? 0,
          }}
          onSubmit={(values, { setSubmitting }) => {
            const data = {
              ...values,
              order_uuid: selectedOrder.order_uuid,
            }
            adminApi.changeStatus(data)
              .then(() => postSubmit(values.order_status, values.payment_status))
              .then(() => handleClose())
              .then(() => setSubmitting(false));
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            validateForm,
            setFieldValue,
          }) => (<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <FormControl fullWidth sx={{ mt: '10px' }}>
              <InputLabel id="order-status-label">Order Status</InputLabel>
              <Select
                value={values.order_status}
                label="Order Status"
                labelId="order-status-label"
                name='order_status'
                onChange={handleChange}
              >
                <MenuItem value={0} selected>Started</MenuItem>
                <MenuItem value={1}>Pending</MenuItem>
                <MenuItem value={2}>Ready To Ship</MenuItem>
                <MenuItem value={3}>Shipping</MenuItem>
                <MenuItem value={4}>Completed</MenuItem>
                <MenuItem value={5}>Cancelled</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: '10px' }}>
              <InputLabel id="payment-status-label">Payment Status</InputLabel>
              <Select
                value={values.payment_status}
                label="Payment Status"
                labelId="payment-status-label"
                name='payment_status'
                onChange={handleChange}
              >
                <MenuItem value={0} selected>Started</MenuItem>
                <MenuItem value={1}>Pending</MenuItem>
                <MenuItem value={2}>Processing</MenuItem>
                <MenuItem value={3}>Paid</MenuItem>
                <MenuItem value={4}>Failed</MenuItem>
              </Select>
            </FormControl>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <LoadingButton
                id="signin-button"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={isSubmitting ? <div /> : undefined}
                // sx={{ mt: 3, mb: 1, borderRadius: '30px', color: '#FECD20' }}
                sx={{
                  padding: '10px 35px',
                  borderRadius: '30px',
                  backgroundColor: '#373736',
                  color: '#FECD20',
                  '&:hover': {
                    backgroundColor: '#252525',
                    color: '#FECD20',
                  },
                  '&:disabled': {
                    backgroundColor: '#C5C5C5',
                  }
                }}
              >
                <span>{isSubmitting ? 'Working' : 'Save Changes'}</span>
              </LoadingButton>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={handleClose}>Go Back</Button>
            </div>
          </Box>)}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeStatusDialog;
