import axios from "axios";

const baseURL = process.env.REACT_APP_ADMIN_HOST_URL;

interface ChangeSubscriptionData {
  user_uuid: string;
  subscription_id: number;
  start_date: string;
  end_date: string;
}

const changeSubscription = (data: ChangeSubscriptionData): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  const headers = { Authorization: `Bearer ${user.access_token}` };

  return new Promise((resolve, reject) => {
    axios({ method: 'POST', url: "user/changeSubscription", data, baseURL, headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default changeSubscription;