import React from 'react';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import icons from '../../../assets/icons';

const CustomListItemButton = ({ icon, callback, text, path, selected = false }:
  {icon: string, callback: (result: string) => void,text: string, path: string, selected: boolean }) => {

  const sx = {
    '&:hover': {
      color: 'white',
    },
    '& .icon': {
      background: selected ? 'black' : 'white',
    },
    '&:hover .icon': {
      background: 'white',
    },
  }

  return(
    <ListItemButton onClick={() => callback(path)} selected={selected} sx={sx}>
      <ListItemIcon>
        <div className='icon' style={{ height: '24px', width: '24px',  mask: `url(${icon})`, WebkitMask: `url(${icon})` }}/>
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  )
}

export default function ListItems({ callback , currentPath = '' }: 
  {callback: (result: string) => void, currentPath: string}){
    const [openOrders, setOpenOrders] = React.useState<boolean>();

    // React.useEffect(() => {
    //   getProfilesDropdown({setProfileList});
    // }, []);

    return(
    <>
    <CustomListItemButton icon={icons.dashbaord_icon.toString()} callback={callback} path={'/portal'} text={'Dashboard'} selected={currentPath === '/portal' || currentPath === 'portal/dashboard'} />
    <ListItemButton onClick={() => setOpenOrders(!openOrders)} sx={{
      '&:hover': {
        color: 'white',
      },
      '& .icon': {
        background: currentPath.includes('/portal/orders') ? 'black' : 'white',
      },
      '&:hover .icon': {
        background: 'white',
      },
    }}>
      <ListItemIcon>
        <div className='icon' style={{ backgroundColor: 'white', height: '24px', width: '24px',  mask: `url(${icons.shopping_cart})`, WebkitMask: `url(${icons.shopping_cart})` }}/>
      </ListItemIcon>
      <ListItemText primary="Orders" />
      {openOrders ? <div className='icon' style={{ backgroundColor: 'white', height: '24px', width: '24px',  mask: `url(${icons.arrow_up_01_icon})`, WebkitMask: `url(${icons.arrow_up_01_icon})` }}/> : <div className='icon' style={{ backgroundColor: 'white', height: '24px', width: '24px',  mask: `url(${icons.arrow_down_01_icon})`, WebkitMask: `url(${icons.arrow_down_01_icon})` }}/>}
    </ListItemButton>
    <Collapse in={openOrders} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton selected={currentPath === '/portal/orders'} onClick={() => callback('/portal/orders')} sx={{
          pl: 4,
          '&:hover': {
            color: 'white',
          },
          '& .icon': {
            background: currentPath.includes('/portal/orders') ? 'black' : 'white',
          },
          '&:hover .icon': {
            background: 'white',
          },
        }}>
          <ListItemIcon>
            <div className='icon' style={{ height: '24px', width: '24px',  mask: `url(${icons.credit_card})`, WebkitMask: `url(${icons.credit_card})` }}/>
          </ListItemIcon>
          <ListItemText primary="NFC Cards" />
        </ListItemButton>
        <ListItemButton selected={currentPath === '/portal/subscriptions'} onClick={() => callback('/portal/subscriptions')} sx={{
          pl: 4,
          '&:hover': {
            color: 'white',
          },
          '& .icon': {
            background: currentPath.includes('portal/subscriptions') ? 'black' : 'white',
          },
          '&:hover .icon': {
            background: 'white',
          },
        }}>
          <ListItemIcon>
            <div className='icon' style={{ height: '24px', width: '24px',  mask: `url(${icons.computer_dollar})`, WebkitMask: `url(${icons.computer_dollar})` }}/>
          </ListItemIcon>
          <ListItemText primary="Subscriptions" />
        </ListItemButton>
      </List>
    </Collapse>
    <CustomListItemButton icon={icons.codesandbox.toString()} callback={callback} path={'/portal/products'} text={'Products'} selected={currentPath === '/portal/products'} />
    <CustomListItemButton icon={icons.user_multiple.toString()} callback={callback} path={'/portal/users'} text={'Users'} selected={currentPath === '/portal/users'} />
    </>
  );
  }