import axios from "axios";

interface ProductDetails {
  name: string;
  category: string;
  sub_category: string;
  price: number;
  discount_price: number;
  front_thumbnail: string;
  back_thumbnail: string;
  info: string;
  is_popular: number;
  source: string;
}

const addProduct = ({ requestBody }: { requestBody: ProductDetails }): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.post("product/add", requestBody)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default addProduct;