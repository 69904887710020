import axios from "axios";

const baseURL = process.env.REACT_APP_ADMIN_HOST_URL;

const getUsers = (params: any): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common["Authorization"] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios({ url: "user/list", baseURL, params })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default getUsers;