import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogContentText } from '@mui/material';

interface ConfirmationDialogProps {
  open: boolean;
  selectedOrder: any;
  handleClose: () => void;
  handleDelete: (uuid: string) => any;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  selectedOrder,
  handleClose,
  handleDelete,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: '25px', minWidth: '400px' } }}>
      <DialogTitle sx={{ fontSize: '31px', fontWeight: 500, textAlign: 'center' }}>Cancel Order?</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: 'center', mb: '20px' }}>
          Are you sure you want to cancel order {selectedOrder?.order_ref_id}?
        </DialogContentText>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Button
            onClick={() => handleDelete(selectedOrder?.order_uuid).then(() => handleClose())}
            sx={{
              padding: '10px 35px',
              borderRadius: '30px',
              backgroundColor: '#373736',
              color: '#FECD20',
              '&:hover': {
                backgroundColor: '#252525',
                color: '#FECD20',
              },
              '&:disabled': {
                backgroundColor: '#C5C5C5',
              }
            }}>
            Confirm
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleClose}>Go Back</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
