import React, { Dispatch, SetStateAction } from 'react';
import { Button, Box, Typography, Avatar } from '@mui/material';
import icons from '../../../assets/icons';
import SubscriptionChip from './SubscriptionChip';

interface UserCardProps {
  user: any;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
  setSelectedUser: Dispatch<SetStateAction<any>>;
}

export default function UserCard({
  user,
  setAnchorEl,
  setSelectedUser,
}: UserCardProps) {
  return (
    <Box sx={{
      p: '2rem',
      borderRadius: "8px",
      bgcolor: "#FFF",
      border: '1px solid #C5C5C5',
    }}>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        py: '1rem',
      }}>
        <Box sx={{
          flex: 1,
          display: 'flex',
          borderRight: '1px solid #C5C5C5',
          gap: '10px',
        }}>
          <Avatar src={user.image ?? icons.profile_picture_icon} alt="" />
          <Box>
            <Typography variant="h4">{user.name}</Typography>
            <Typography
              variant="body1"
              component={'a'}
              href={`mailto:${user.email}`}
              sx={{
                color: '#a7a7a7',
                textDecoration: 'none',
              }}
            >
              {user.email}
            </Typography>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRight: '1px solid #C5C5C5',
          padding: '0 2rem',
        }}>
          <Typography variant="h4">Referral Number</Typography>
          <Typography variant="body1">{user.referral_code}</Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRight: '1px solid #C5C5C5',
          padding: '0 2rem',
        }}>
          <Typography variant="h4">Subscription Plan</Typography>
          <SubscriptionChip subscription_id={user.subscription_id} />
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Button
            variant="contained"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setSelectedUser(user);
            }}
            endIcon={<img src={icons.arrow_down_01_icon} alt="" />}
            sx={{ mx: '10px' }}
          >
            More Actions
          </Button>
        </Box>
      </Box>
    </Box>
  );
};