import React from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Toolbar,Box, List,Typography, IconButton} from '@mui/material';
import {ListItems, SecondaryListItems} from './components';
import { useLocation, useNavigate } from 'react-router-dom'
// import pixel_logo from './assets/pixel_logo.svg'
import icons from "src/assets/icons";
const drawerWidth: number = 240;

const drawerTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#000000',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    text:{
      primary: "#FFF"
    },
    background: {
      paper: "#3C3C3B",
    },
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          fontWeight: 500,
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            '& .MuiListItemText':{
              // '&.MuiTypography-root':{
                color: '#000000 !important',
              },
            // },
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            color: "#000000",
          },
          "&:selected": {
            color: "#000000"
          } 
        },
      },
    },
  },

});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        width: drawerWidth,
      },
      whiteSpace: 'nowrap',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        // overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.down('md')]: {
          width: '0%',
        },
        [theme.breakpoints.up('md')]: {
          width: 0,
        }
      }),
    },
  }),
);
const ListTitle = styled('div')({
  padding: '1rem'
})
export default function DrawerComponent(
  {toggleDrawer,open}:{toggleDrawer:any,open:boolean}
){

  const navigate = useNavigate();
  const location = useLocation();
  
  return(
    <ThemeProvider theme={drawerTheme}>
      <Drawer  PaperProps={{ xs : {position: 'absolute',  }}}variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: [1],
  
            }}
          >
            <Box style={{
              justifyContent: 'flex-end',
              width: '-webkit-fill-available',
              display: 'flex',
              flexDirection: 'row',
              paddingTop: '1rem',
            }}>
            <IconButton onClick={toggleDrawer}>
              <img src={icons.menu_burger.toString()} alt="menu burger"/>
            </IconButton>
            </Box>

            <div style={{padding: '1rem 0 3rem 0'}}>
              <img src={icons.pixel_logo_white.toString()} style={{maxWidth:'133px',maxHeight:"33"}} alt="Pixel Logo"/>
              </div>
            
          </Toolbar>

          <List component="nav">
            <ListItems callback={navigate} currentPath={location.pathname} />
            <ListTitle>
              <Typography> Setting </Typography>
            </ListTitle>
            
            <SecondaryListItems callback={navigate} currentPath={location.pathname}/>
          </List>
        </Drawer>
    </ThemeProvider>
  ) ;
};