import React, { useState, MouseEvent } from "react";
import { Box, Tab, Tabs, Typography, Menu, MenuItem, TextField, Button, IconButton, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "./components/confirmationDialog";
import FilterDialog from "./components/filterDialog";
import UserCard from "./components/userCard";
import icons from "../../assets/icons";

interface OrderComponentProps {
  users: any[];
  handleDelete: (uuid: string) => any;
  search: any;
  setSearch: (search: string) => any;
}

export default function UsersComponent({ users, handleDelete, search, setSearch }: OrderComponentProps) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const openSortMenu = Boolean(anchorEl);

  return (
    <>
      <ConfirmationDialog
        open={open}
        selectedUser={selectedUser}
        handleClose={() => setOpen(false)}
        handleDelete={handleDelete}
      />
      <FilterDialog
        open={openFilter}
        handleClose={() => setOpenFilter(false)}
        search={search}
        setSearch={setSearch}
      />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}>
        <Tabs value={selectedTab} onChange={handleTabChange} sx={{
          borderBottom: '1px solid #C5C5C5',
        }}>
          <Tab label="All" />
        </Tabs>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <Typography variant="h2">All List</Typography>
          {/* <Button
            variant="contained"
            onClick={() => navigate('/portal/products/create')}
          >
            Create Product
          </Button> */}
        </Box>
        <Box
          sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
          component={'form'}
          onSubmit={(e) => {
            e.preventDefault();
            setSearch({
              ...search,
              search: e.target[0].value,
            });
          }}
        >
          <TextField
            fullWidth
            size="small"
            type="text"
            placeholder="Search users by name"
          />
          <Button
            type="submit"
            variant="contained"
            sx={{ borderRadius: '8px' }}
          >
            Search
          </Button>
          <Box>
            <Button
              variant={Boolean(search.subscription_id) ? "contained" : "outlined"}
              sx={{
                color: '#373736',
                borderRadius: '8px',
              }}
              startIcon={<img src={icons.filter_list} alt="filter" />}
              onClick={() => setOpenFilter(true)}
            >
              Filter
            </Button>
          </Box>
          <IconButton onClick={handleClick}>
            <img src={icons.arrow_up_down_round} alt="sort" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={openSortMenu}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              sx: { width: '160px' },
            }}
            slotProps={{
              paper: {
                sx: { borderRadius: '7px' },
              }
            }}
          >
            <MenuItem
              selected={search.order_by === 'created_at' && search.order === 'asc'}
              sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}
              onClick={() => {
                setSearch({
                  ...search,
                  order_by: 'created_at',
                  order: 'asc',
                });
                setAnchorEl(null);
              }}
            >
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Date (Asc)</ListItemText>
            </MenuItem>
            <MenuItem
              selected={search.order_by === 'created_at' && search.order === 'desc'}
              sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}
              onClick={() => {
                setSearch({
                  ...search,
                  order_by: 'created_at',
                  order: 'desc',
                });
                setAnchorEl(null);
              }}
            >
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Date (Desc)</ListItemText>
            </MenuItem>
            <MenuItem
              selected={search.order_by === 'name' && search.order === 'asc'}
              sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}
              onClick={() => {
                setSearch({
                  ...search,
                  order_by: 'name',
                  order: 'asc',
                });
                setAnchorEl(null);
              }}
            >
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Name (Asc)</ListItemText>
            </MenuItem>
            <MenuItem
              selected={search.order_by === 'name' && search.order === 'desc'}
              sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}
              onClick={() => {
                setSearch({
                  ...search,
                  order_by: 'name',
                  order: 'desc',
                });
                setAnchorEl(null);
              }}
            >
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Name (Desc)</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}>
          {users.map((user, index) => <UserCard key={index} user={user} setAnchorEl={setMoreAnchorEl} setSelectedUser={setSelectedUser} />)}
          <Menu
            anchorEl={moreAnchorEl}
            open={Boolean(moreAnchorEl)}
            onClose={() => setMoreAnchorEl(null)}
          >
            <MenuItem onClick={() => {
              navigate(`/portal/users/${selectedUser.uuid}`);
              setMoreAnchorEl(null);
            }} sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
              View Details
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </>);
}