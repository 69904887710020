import React from 'react';
import { Box, Typography } from '@mui/material';

interface SubscriptionChipProps {
  subscription_id: number;
}

export default function SubscriptionChip({ subscription_id }: SubscriptionChipProps) {
  let plan = '';
  let background = '';

  switch (subscription_id) {
    case 2:
      plan = 'Lifestyle (Monthly)';
      background = 'linear-gradient(135deg, #00C3CF, #FFCD1C)';
      break;
    case 3:
      plan = 'Lifestyle (Yearly)';
      background = 'linear-gradient(135deg, #00C3CF, #FFCD1C)';
      break;
    case 4:
      plan = 'Lifestyle (Trial)';
      background = 'linear-gradient(135deg, #00C3CF, #FFCD1C)';
      break;
    case 5:
      plan = 'Lifestyle (Lifetime)';
      background = 'linear-gradient(135deg, #00C3CF, #FFCD1C)';
      break;
    default:
      plan = 'Free';
      background = 'linear-gradient(135deg, #FE2E00, #FFCD1C)';
  }

  return (
    <Box sx={{ p: '5px 10px', background, borderRadius: '8px', textAlign: 'center' }}>
      <Typography sx={{ textTransform: 'uppercase', color: 'white' }}>
        {plan}
      </Typography>
    </Box>
  );
};