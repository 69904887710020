import axios from "axios";

interface VariantDetails {
  product_uuid: string;
  variation_uuid: string;
  category: string;
  variation_name: string;
  stock: string;
  front_thumbnail?: string;
  back_thumbnail?: string;
  sample_thumbnail?: string;
  sample_view_side?: number;
}

const editProductVariation = ({ requestBody }: { requestBody: VariantDetails }): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.post("product/editProductVariation", requestBody)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default editProductVariation;