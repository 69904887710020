import React from "react";
import { Box } from "@mui/system";
import { styled } from '@mui/system';
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
const Path = styled('div')({
  fontSize: 16
});

export default function ContentBreadcrumb(){
  const navigate = useNavigate()
  const breadcrumb =  useSelector(state => state.admin.breadcrumb)
  
  return(
    <Box sx={{display:{ xs: "none", md:"flex"}, gap:"0.3rem"}} >
      {breadcrumb.map((item,index) => {
        if(item.path === null){
          return <Path key={index} sx={{color:"grey"}}> {item.name}</Path>
        }else{
          return <Path key={index} sx={{cursor:'pointer'}} onClick={()=> navigate(item.path)}>{item.name} {'>>'} </Path> 
        }
      }
      )}
    </Box>
  )
}