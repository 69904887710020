import rootReducer from "./reducer";
import rootMiddleware from "./middleware";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: rootReducer,
  middleware: rootMiddleware,
  devTools: process.env.NODE_ENV !== "production"  
});

export default store;
