import React, { Dispatch, SetStateAction, useState, MouseEvent } from "react";
import { Button, Box, Tab, Tabs, Typography, TextField, Menu, MenuItem, Avatar, styled, ListItemText, IconButton } from "@mui/material";
import FormBox from "../../components/form-box/formBox";
import { Formik } from "formik";
import icons from "../../assets/icons";
import adminApi from "../../app/api/admin";
import { useNavigate } from "react-router-dom";
import ChangeStatusDialog from "./component/changeStatusDialog";
import ConfirmationDialog from "./component/confirmationDialog";
import SuccessDialog from "./component/successDialog";
import FailDialog from "./component/failDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import FilterDialog from "./component/filterDialog";

interface OrderComponentProps {
  orders: any[];
  handleCancel: (order_uuid: string) => void;
  setOrders: Dispatch<SetStateAction<any[]>>;
  search: any;
  setSearch: Dispatch<SetStateAction<any>>;
}

const DetailColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 'inherit',
  padding: '0 2rem',
});

export default function OrderComponent({ orders, handleCancel, setOrders, search, setSearch }: OrderComponentProps) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFail, setOpenFail] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOrder, setSelectedOrder] = useState<any>();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);

    if (newValue > 0) {
      setSearch({
        ...search,
        status: newValue - 1,
      });
    } else {
      const newSearch = { ...search };
      delete newSearch.status;
      setSearch(newSearch);
    }
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const openSortMenu = Boolean(anchorEl);

  const postSubmit = (order_status: number, payment_status: number) => {
    const index = orders.indexOf(selectedOrder);
    setOrders([
      ...orders.slice(0, index),
      {
        ...orders[index],
        status: order_status,
        payment_status,
      },
      ...orders.slice(index + 1),
    ]);
  }

  const getOrderStatus = (stat: number) => {
    switch (stat) {
      case 0:
        return {
          text: 'Started',
          bgcolor: '#C5C5C5',
          color: '#373736',
        };
      case 1:
        return {
          text: 'Pending',
          bgcolor: '#FFF1DB',
          color: '#FFB443',
        };
      case 2:
        return {
          text: 'Ready To Ship',
          bgcolor: '#E5EBFF',
          color: '#2E5CFF',
        };
      case 3:
        return {
          text: 'Shipping',
          bgcolor: '#F3E4FF',
          color: '#A32EFF',
        };
      case 4:
        return {
          text: 'Completed',
          bgcolor: '#E5FFDE',
          color: '#26A800',
        };
      default:
        return {
          text: 'Cancelled',
          bgcolor: '#FFDEDC',
          color: '#EB3325',
        };
    }
  }

  const getPaymentStatus = (stat: number) => {
    switch (stat) {
      case 0:
        return {
          text: 'Started',
          bgcolor: '#C5C5C5',
          color: '#373736',
        };
      case 1:
        return {
          text: 'Pending',
          bgcolor: '#FFF1DB',
          color: '#FFB443',
        };
      case 2:
        return {
          text: 'Processing',
          bgcolor: '#F3E4FF',
          color: '#A32EFF',
        };
      case 3:
        return {
          text: 'Paid',
          bgcolor: '#E5FFDE',
          color: '#26A800',
        };
      default:
        return {
          text: 'Failed',
          bgcolor: '#FFDEDC',
          color: '#EB3325',
        };
    }
  }

  return <>
    <ChangeStatusDialog
      open={openChangeStatus}
      selectedOrder={selectedOrder}
      handleClose={() => setOpenChangeStatus(false)}
      postSubmit={postSubmit}
    />
    <ConfirmationDialog
      open={open}
      selectedOrder={selectedOrder}
      handleClose={() => setOpen(false)}
      handleDelete={handleCancel}
    />
    <FilterDialog
      open={openFilter}
      handleClose={() => setOpenFilter(false)}
      search={search}
      setSearch={setSearch}
    />
    <SuccessDialog open={openSuccess} handleClose={() => setOpenSuccess(false)} />
    <FailDialog errorMessage={errorMessage} open={openFail} handleClose={() => setOpenFail(false)} />
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    }}>
      <Tabs value={selectedTab} onChange={handleTabChange} sx={{
        borderBottom: '1px solid #C5C5C5',
      }}>
        <Tab label="All List" />
        <Tab label="Started" />
        <Tab label="Pending" />
        <Tab label="Ready To Ship" />
        <Tab label="Shipping" />
        <Tab label="Completed" />
        <Tab label="Cancelled" />
      </Tabs>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        <Typography variant="h2">All List</Typography>
        <Button
          variant="contained">
          Export as CSV
        </Button>
      </Box>
      <Box
        sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
        component={'form'}
        onSubmit={(e) => {
          e.preventDefault();
          setSearch({
            ...search,
            search: e.target[0].value,
          });
        }}
      >
        <TextField
          fullWidth
          size="small"
          type="text"
          placeholder="Search cards by customers' name"
        />
        <Button
          type="submit"
          variant="contained"
          sx={{ borderRadius: '8px' }}
        >
          Search
        </Button>
        <Box>
          <Button
            variant={Boolean(search.payment_status) ? "contained" : "outlined"}
            sx={{
              color: '#373736',
              borderRadius: '8px',
            }}
            startIcon={<img src={icons.filter_list} alt="filter" />}
            onClick={() => setOpenFilter(true)}
          >
            Filter
          </Button>
        </Box>
        <IconButton onClick={handleClick}>
          <img src={icons.arrow_up_down_round} alt="sort" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={openSortMenu}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            sx: { width: '160px' },
          }}
          slotProps={{
            paper: {
              sx: { borderRadius: '7px' },
            }
          }}
        >
          <MenuItem
            selected={search.order_by === 'created_at' && search.order === 'asc'}
            sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}
            onClick={() => {
              setSearch({
                ...search,
                order_by: 'created_at',
                order: 'asc',
              });
              setAnchorEl(null);
            }}
          >
            <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Date (Asc)</ListItemText>
          </MenuItem>
          <MenuItem
            selected={search.order_by === 'created_at' && search.order === 'desc'}
            sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}
            onClick={() => {
              setSearch({
                ...search,
                order_by: 'created_at',
                order: 'desc',
              });
              setAnchorEl(null);
            }}
          >
            <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Date (Desc)</ListItemText>
          </MenuItem>
          <MenuItem
            selected={search.order_by === 'name' && search.order === 'asc'}
            sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}
            onClick={() => {
              setSearch({
                ...search,
                order_by: 'name',
                order: 'asc',
              });
              setAnchorEl(null);
            }}
          >
            <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Name (Asc)</ListItemText>
          </MenuItem>
          <MenuItem
            selected={search.order_by === 'name' && search.order === 'desc'}
            sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}
            onClick={() => {
              setSearch({
                ...search,
                order_by: 'name',
                order: 'desc',
              });
              setAnchorEl(null);
            }}
          >
            <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Name (Desc)</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}>
        {orders.length > 0 && orders.map((order, index) => <FormBox key={index}>
          <Typography fontWeight="500">Order ID: {order.order_ref_id}</Typography>
          <Box sx={{
            display: 'flex',
            gap: '5px',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            py: '1rem',
          }}>
            <Avatar src={order.customer.image} />
            <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
              <Typography variant="h4">{order?.customer.name}</Typography>
              <Typography variant="body1">{order?.customer.referral_code}</Typography>
            </DetailColumn>
            <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
              <Typography variant="h4">Price</Typography>
              <Typography variant="body1">{order.currency}{order.total_amount}</Typography>
            </DetailColumn>
            <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
              <Typography variant="h4">Date</Typography>
              <Typography variant="body1">{order.created_at}</Typography>
            </DetailColumn>
            <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
              <Typography variant="h4">Order Status</Typography>
              <Typography
                variant="body1"
                sx={{
                  padding: '3px 8px',
                  fontSize: '13px',
                  textAlign: 'center',
                  borderRadius: '8px',
                  border: `1px solid ${getOrderStatus(Number(order.status)).color}`,
                  color: getOrderStatus(Number(order.status)).color,
                  bgcolor: getOrderStatus(Number(order.status)).bgcolor,
                }}
              >
                {getOrderStatus(Number(order.status)).text}
              </Typography>
            </DetailColumn>
            <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
              <Typography variant="h4">Payment Status</Typography>
              <Typography
                variant="body1"
                sx={{
                  padding: '3px 8px',
                  fontSize: '13px',
                  textAlign: 'center',
                  borderRadius: '8px',
                  border: `1px solid ${getPaymentStatus(Number(order.payment_status)).color}`,
                  color: getPaymentStatus(Number(order.payment_status)).color,
                  bgcolor: getPaymentStatus(Number(order.payment_status)).bgcolor,
                }}
              >
                {getPaymentStatus(Number(order.payment_status)).text}
              </Typography>
            </DetailColumn>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <Button
                variant="contained"
                onClick={(e) => {
                  setSelectedOrder(order);
                  setMoreAnchorEl(e.currentTarget);
                }}
                endIcon={<img src={icons.arrow_down_01_icon} alt="" />}
              >
                More Action
              </Button>
            </Box>
          </Box>
          <hr></hr>
          <Formik
            initialValues={{
              courier_name: order.order_shipping?.courier ?? '',
              tracking_number: order.order_shipping?.track_number ?? '',
              tracking_url: order.order_shipping?.track_url ?? '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              adminApi.updateShipping({
                ...values,
                order_uuid: order.order_uuid,
              })
                .then(() => setOpenSuccess(true))
                .catch(error => {
                  setErrorMessage(error.message);
                  setOpenFail(true);
                })
                .finally(() => setSubmitting(false));
            }}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              validateForm,
              setFieldValue,
            }) => (
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{
                display: 'grid',
                gridTemplateColumns: '5fr 1fr',
              }}>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '1.5rem',
                }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography>Courier Name</Typography>
                    <TextField
                      name="courier_name"
                      size="small"
                      value={values.courier_name}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography>Tracking Number</Typography>
                    <TextField
                      name="tracking_number"
                      size="small"
                      value={values.tracking_number}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography>Shipment Tracking URL</Typography>
                    <TextField
                      name="tracking_url"
                      size="small"
                      value={values.tracking_url}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  ml: '1rem',
                  borderLeft: '1px solid #C5C5C5',
                }}>
                  <LoadingButton
                    id="confirm-button"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    loadingPosition="start"
                    startIcon={isSubmitting ? <div /> : undefined}
                  >
                    <span>{isSubmitting ? 'Working' : 'Confirm'}</span>
                  </LoadingButton>
                </Box>
              </Box>
            )}
          </Formik>
        </FormBox>)}
        <Menu
          anchorEl={moreAnchorEl}
          open={Boolean(moreAnchorEl)}
          onClose={() => setMoreAnchorEl(null)}
        >
          <MenuItem onClick={() => {
            navigate(selectedOrder.order_uuid);
            setMoreAnchorEl(null);
          }} sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
            Order Details
          </MenuItem>
          <MenuItem onClick={() => {
            // navigate(selectedOrder.order_uuid);
            setOpenChangeStatus(true);
            setMoreAnchorEl(null);
          }}>
            Change Status
          </MenuItem>
          {Number(selectedOrder?.status) !== 5 && <MenuItem onClick={() => {
            setOpen(true);
            setMoreAnchorEl(null);
          }} sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
            Cancel Order
          </MenuItem>}
        </Menu>
      </Box>
    </Box>
  </>
}