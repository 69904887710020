import React, { Dispatch, SetStateAction } from 'react';
import { Button, Box, Typography, Avatar, Checkbox } from '@mui/material';
import icons from '../../../assets/icons';

interface SubscriptionCardProps {
  sub: any;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
  setSelectedSub: Dispatch<SetStateAction<any>>;
  handleChange: () => void;
}

export default function SubscriptionCard({
  sub,
  setAnchorEl,
  setSelectedSub,
  handleChange,
}: SubscriptionCardProps) {
  const orderStatus = [
    'Started',
    'Pending',
    'Ready to Ship',
    'Shipping',
    'Completed',
    'Cancelled',
  ];
  const paymentStatus = [
    'Started',
    'Pending',
    'Processing',
    'Paid',
    'Failed',
  ];

  return (
    <Box sx={{
      p: '2rem',
      borderRadius: "8px",
      bgcolor: "#FFF",
      border: '1px solid #C5C5C5',
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          edge='start'
          checked={sub.is_checked}
          onChange={handleChange}
        />
        <Typography sx={{ flex: 1, fontWeight: 500 }}>Order ID: {sub.order_ref_id} [{sub.plan}]</Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        py: '1rem',
      }}>
        <Box sx={{
          flex: 1,
          display: 'flex',
          borderRight: '1px solid #C5C5C5',
          gap: '10px',
        }}>
          <Avatar src={sub.image ?? icons.profile_picture_icon} alt="" />
          <Box>
            <Typography variant="h4">{sub.customer.name}</Typography>
            <Typography
              variant="body1"
              component={'a'}
              href={`mailto:${sub.order_payment.bill_email}`}
              sx={{
                color: '#a7a7a7',
                textDecoration: 'none',
              }}
            >
              {sub.order_payment.bill_email}
            </Typography>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRight: '1px solid #C5C5C5',
          padding: '0 2rem',
        }}>
          <Typography variant="h4">Payment Date</Typography>
          <Typography variant="body1">{sub.date}</Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRight: '1px solid #C5C5C5',
          padding: '0 2rem',
        }}>
          <Typography variant="h4">Price</Typography>
          <Typography variant="body1">{sub.price}</Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRight: '1px solid #C5C5C5',
          padding: '0 1rem',
        }}>
          <Typography variant="h4">Order Status</Typography>
          <Typography variant="body1">{orderStatus[Number(sub.status)]}</Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRight: '1px solid #C5C5C5',
          padding: '0 1rem',
        }}>
          <Typography variant="h4">Payment Status</Typography>
          <Typography variant="body1">{paymentStatus[Number(sub.payment_status)]}</Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Button
            variant="contained"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setSelectedSub(sub);
            }}
            endIcon={<img src={icons.arrow_down_01_icon} alt="" />}
            sx={{ mx: '10px' }}
          >
            More Actions
          </Button>
        </Box>
      </Box>
    </Box>
  );
};