import axios from "axios";

const baseURL = process.env.REACT_APP_ADMIN_HOST_URL;

const cancelSubscription = (order_uuid: string): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common["Authorization"] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios({ url: "order/cancelSubscription", baseURL, params: { order_uuid } })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default cancelSubscription;