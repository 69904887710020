import axios from "axios";

const baseURL = process.env.REACT_APP_ADMIN_HOST_URL;

interface ChangeStatusData {
  order_uuid: string;
  order_status: number;
  payment_status: number;
}

const changeStatus = (data: ChangeStatusData): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common["Authorization"] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios({ method: 'POST', url: "order/changeStatus", data, baseURL })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default changeStatus;