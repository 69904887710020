import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Box, Typography, TextField, Autocomplete } from '@mui/material';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';

interface AddCardDialogProps {
  open: boolean;
  selectedUser: any;
  handleClose: () => void;
  postSubmit: () => void;
  products?: any[];
  items: any[];
  setFieldValue: (field: string, value: any) => void;
}

const AddItemDialog: React.FC<AddCardDialogProps> = ({
  open,
  selectedUser,
  handleClose,
  postSubmit,
  products,
  items,
  setFieldValue,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: '25px', minWidth: '400px' } }}>
      <DialogTitle sx={{ fontSize: '31px', fontWeight: 500, textAlign: 'center' }}>Add Item</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            product: {} as any,
            product_id: 0,
            img_url: '',
            name: '',
            position: '',
            quantity: 1,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setFieldValue('items', [
              ...items,
              {
                ...values.product,
                name: values.name,
                position: values.position,
                quantity: values.quantity,
              }
            ]);
            postSubmit();
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            validateForm,
            setFieldValue,
          }) => (<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Box>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{
                  width: '250px',
                  height: '250px',
                  border: '1px solid #A7A7A7',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}>
                  {values.img_url && <img
                    alt='Front thumbnail'
                    src={values.img_url !== undefined ? typeof values.img_url === 'string' ? values.img_url : URL.createObjectURL(values.img_url) : undefined}
                    style={{
                      maxWidth: '90%',
                      maxHeight: '90%',
                      borderRadius: '8px',
                      boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
                    }}
                  />}
                </Box>
                {/* <Button
                  component="label"
                  sx={{
                    margin: 3,
                    height: '40px',
                    borderRadius: '30px',
                    color: 'black',
                    bgcolor: '#FECD20',
                  }}>
                  Upload image
                  <VisuallyHiddenInput name="front_thumbnail" type="file" onChange={(e: any) => {
                    setFieldValue(`img_url`, e.target.files[0]);
                  }} accept="image/png, image/jpeg" />
                </Button> */}
              </div>
            </Box>
            {/* <Box>
              <Typography component='label' htmlFor='card_status' variant='h5'>
                Card Status
              </Typography>
              <Select
                id="card_status"
                value={values.status}
                sx={{ marginTop: '0.6rem', '&:focus': { border: 'solid #FECD20' } }}
                fullWidth
                onChange={(e) => setFieldValue('status', e.target.value)}
              >
                <MenuItem value={1}>Processing</MenuItem>
                <MenuItem value={2}>Shipping</MenuItem>
                <MenuItem value={3}>Completed</MenuItem>
                <MenuItem value={4}>Cancelled</MenuItem>
              </Select>
            </Box> */}
            <Box>
              <Typography component='label' className='required' variant='h5'>
                Product
              </Typography>
              <Autocomplete
                value={products?.filter(prod => prod.id === values.product_id)[0]}
                options={products ?? []}
                autoHighlight
                disableClearable
                clearOnBlur
                // popupIcon={<img src={icons.arrow_down_01_icon as unknown as string} alt='Open dropdown' />}
                getOptionLabel={option => option.card_name}
                // filterOptions={filterOptions()}
                // defaultValue={defaultTimezone}
                onChange={(event: any, newValue: any | null) => {
                  setFieldValue('product', newValue);
                  setFieldValue('product_id', newValue?.id);
                  setFieldValue('img_url', newValue?.card_thumbnail);
                }}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props} key={option.uuid}>
                    {option.card_name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    color='secondary'
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    sx={{ background: 'white' }}
                  />
                )}
              />
            </Box>
            <TextField
              color='secondary'
              margin="dense"
              required
              fullWidth
              name="name"
              placeholder="Name on card"
              value={values.name}
              onChange={handleChange}
            />
            <TextField
              color='secondary'
              margin="dense"
              required
              fullWidth
              name="position"
              placeholder="Position"
              value={values.position}
              onChange={handleChange}
            />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <LoadingButton
                id="signin-button"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={isSubmitting ? <div /> : undefined}
                // sx={{ mt: 3, mb: 1, borderRadius: '30px', color: '#FECD20' }}
                sx={{
                  padding: '10px 35px',
                  borderRadius: '30px',
                  backgroundColor: '#373736',
                  color: '#FECD20',
                  '&:hover': {
                    backgroundColor: '#252525',
                    color: '#FECD20',
                  },
                  '&:disabled': {
                    backgroundColor: '#C5C5C5',
                  }
                }}
              >
                <span>{isSubmitting ? 'Working' : 'Submit'}</span>
              </LoadingButton>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={handleClose}>Go Back</Button>
            </div>
          </Box>)}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddItemDialog;
