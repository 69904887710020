
import React from "react";
import Drawer from "../drawer";
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import {Outlet } from 'react-router-dom';
import {
  Box,
  Container,
  CssBaseline
} from '@mui/material';
import ContentBreadcrumb from "../contentBreadcrumb";
import icons from "src/assets/icons";


const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    [theme.breakpoints.down('md')]: {
      width:0,
    },
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },

    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AdminLayout() {
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{
      display: 'flex',
    }}>
      <CssBaseline />
      <AppBar position="absolute" open={open} elevation={0} style={{minHeight: '75px'}}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              pt: "1rem"
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <img src={icons.menu_burger.toString()} alt="menu burger"/>
            </IconButton>
            {/* Flex space between, need an empty box to setting button stick to right */}
            <Box><ContentBreadcrumb /></Box>
            {/* <Button style={{
              width:"unset",
              height: "50px",
              backgroundColor: "#FECD20",
              color:'#000000'
            }}
            onClick={() => navigate(PORTAL_SETTINGS)}>
              <Box style={{
                    display: 'grid',
                    alignItems: 'center',
                    gridTemplateColumns: '1fr 4fr',
                    gap: '1rem'
              }}>
              <img src={userImage || images.empty_avatar}
              style={{borderRadius: "50%", width:"40px",height:"40px"}}/>
              <Box style={{display:'flex',justifyContent: 'flex-start'}} >
              <Typography style={{fontSize:"13px",width:"max-content",overflow:"hidden"}}>
              {username}
              </Typography>
              </Box>

              </Box>
            </Button> */}
          </Toolbar>
        </AppBar>
        <Drawer toggleDrawer={toggleDrawer} open={open}/>
        <Box
          component="main"
          sx={{
            backgroundColor:'#F9FAFC',
            // backgroundColor: (theme) =>
            //   theme.palette.mode === 'light'
            //     ? 'white'
            //     : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4}}>
            <Outlet/>
          </Container>
        </Box>
    </Box>
  );
}