import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  CssBaseline
} from '@mui/material';
import { Outlet } from 'react-router-dom';
const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#FECD20',
    },
    secondary: {
      main: '#373736',
      contrastText: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h1: {
      fontSize: '42px', 
      fontWeight: 400,
    },
    h2: {
      fontSize: '35px', 
    },
    h3: {
      fontSize: '20px',
      fontWeight: 500,
    },
    h4: {
      fontSize: '16px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    button: {
      textTransform: 'none',
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#FFF',
          backgroundColor: '#3C3C3B',
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        // root: {
        //   fontSize: '13px',
        //   fontWeight: 500,
        // }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius:"30px"
        }
      },
    },
    MuiToolbar:{
      styleOverrides: {
        root: {
          justifyContent: 'space-between'
        }
      }
    },
  },
});

export default function MainLayout(){
  return <>
    <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <Outlet/>
    </ThemeProvider>
  </>
}