import React, { useEffect, useState } from "react";
import UsersComponent from "./users.component";
import usersApi from "../../app/api/admin";
import FileHelper from "../../lib/helpers/fileHelper";

export default function ProductsContainer() {
  const [users, setUsers] = useState<any[]>([]);
  const [search, setSearch] = useState({});

  const handleDelete = (uuid: string) => {
    console.log(`Delete user ${uuid} (unimplemented)`);
  }

  useEffect(() => {
    usersApi.getUsers(search).then((response) => {
      Promise.all((response.data).map(async (user) => {
        let presigned_url = null;
        if (Boolean(user.image)) presigned_url = await FileHelper.getUrl(user.image);

        return {
          ...user,
          image: presigned_url,
        };
      })).then(setUsers);
    });
  }, [search]);

  return <UsersComponent
    users={users}
    handleDelete={handleDelete}
    search={search}
    setSearch={setSearch}
  />
}