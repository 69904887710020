import { styled, Switch } from "@mui/material";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: '70px',
  '& .MuiSwitch-switchBase.Mui-checked': {
    transform: 'translateX(32px)',
    color: 'white',
  },
  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#26A800',
    opacity: 1,
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      // content: '"ON"',
      // fontSize: '12px',
      // left: 22,
    },
    '&:after': {
      // content: '"OFF"',
      // fontSize: '12px',
      // right: 22,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export default CustomSwitch;