import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Box, styled, Typography, Select, MenuItem } from '@mui/material';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import FileHelper from '../../../lib/helpers/fileHelper';
import { v4 as uuidv4 } from 'uuid';
import adminApi from '../../../app/api/admin/';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface AddCardDialogProps {
  open: boolean;
  selectedUser: any;
  handleClose: () => void;
  postSubmit: () => void;
  card?: any;
}

const AddCardDialog: React.FC<AddCardDialogProps> = ({
  open,
  selectedUser,
  handleClose,
  postSubmit,
  card,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: '25px', minWidth: '400px' } }}>
      <DialogTitle sx={{ fontSize: '31px', fontWeight: 500, textAlign: 'center' }}>{Boolean(card) ? 'Edit' : 'Add'} Card</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            card_uuid: card?.uuid,
            user_uuid: selectedUser?.uuid,
            img_url: card?.image ?? '',
            status: card?.status ?? 1,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const requestBody = {
              user_uuid: values.user_uuid,
              card_uuid: values.card_uuid,
              img_url: values.img_url,
              status: values.status,
            };

            if (typeof values.img_url !== 'string') {
              requestBody['img_url'] = await FileHelper.uploadFile(
                `user/${values.user_uuid}/card/image/${uuidv4()}_${values.img_url.name}`,
                values.img_url
              );
            } else if (values.img_url === card?.image) { // No change
              delete requestBody.img_url;
            }

            // If editing
            if (Boolean(card)) {
              adminApi.updateCard(requestBody)
                .then(() => postSubmit())
                .then(() => handleClose())
                .finally(() => setSubmitting(false));
            } else {
              adminApi.addCard(requestBody)
                .then(() => postSubmit())
                .then(() => handleClose())
                .finally(() => setSubmitting(false));
            }
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            validateForm,
            setFieldValue,
          }) => (<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Box>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{
                  width: '250px',
                  height: '250px',
                  border: '1px solid #A7A7A7',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}>
                  {values.img_url && <img
                    alt='Front thumbnail'
                    src={values.img_url !== undefined ? typeof values.img_url === 'string' ? values.img_url : URL.createObjectURL(values.img_url) : undefined}
                    style={{
                      maxWidth: '90%',
                      maxHeight: '90%',
                      borderRadius: '8px',
                      boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
                    }}
                  />}
                </Box>
                <Button
                  component="label"
                  sx={{
                    margin: 3,
                    height: '40px',
                    borderRadius: '30px',
                    color: 'black',
                    bgcolor: '#FECD20',
                  }}>
                  Upload image
                  <VisuallyHiddenInput name="front_thumbnail" type="file" onChange={(e: any) => {
                    setFieldValue(`img_url`, e.target.files[0]);
                  }} accept="image/png, image/jpeg" />
                </Button>
              </div>
            </Box>
            <Box>
              <Typography component='label' htmlFor='card_status' variant='h5'>
                Card Status
              </Typography>
              <Select
                id="card_status"
                value={values.status}
                sx={{ marginTop: '0.6rem', '&:focus': { border: 'solid #FECD20' } }}
                fullWidth
                onChange={(e) => setFieldValue('status', e.target.value)}
              >
                <MenuItem value={1}>Processing</MenuItem>
                <MenuItem value={2}>Shipping</MenuItem>
                <MenuItem value={3}>Completed</MenuItem>
                <MenuItem value={4}>Cancelled</MenuItem>
              </Select>
            </Box>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <LoadingButton
                id="signin-button"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={isSubmitting ? <div /> : undefined}
                // sx={{ mt: 3, mb: 1, borderRadius: '30px', color: '#FECD20' }}
                sx={{
                  padding: '10px 35px',
                  borderRadius: '30px',
                  backgroundColor: '#373736',
                  color: '#FECD20',
                  '&:hover': {
                    backgroundColor: '#252525',
                    color: '#FECD20',
                  },
                  '&:disabled': {
                    backgroundColor: '#C5C5C5',
                  }
                }}
              >
                <span>{isSubmitting ? 'Working' : 'Submit'}</span>
              </LoadingButton>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={handleClose}>Go Back</Button>
            </div>
          </Box>)}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddCardDialog;
