import React, { useEffect, useState } from "react";
import { Button, Box, Typography, TextField, styled, IconButton, Select, MenuItem } from "@mui/material";
import icons from "../../../assets/icons";
import CustomSwitch from "../../../components/customSwitch";
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik } from "formik";
import productApi from "../../../app/api/product";
import FileHelper from "../../../lib/helpers/fileHelper";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from "react-router-dom";

interface EditProductsComponentProps {
  products: any[];
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function EditProductsComponent({ products }: EditProductsComponentProps) {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [deletedVariationUUIDs, setDeletedVariationUUIDs] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>();

  const handleDelete = (variations, index, updateFx) => {
    if (Boolean(variations[index].uuid)) {
      setDeletedVariationUUIDs([
        ...deletedVariationUUIDs,
        variations[index].uuid,
      ])
    }

    updateFx('variations', [
      ...variations.slice(0, index),
      ...variations.slice(index + 1),
    ]);
  }

  useEffect(() => {
    if (Boolean(uuid)) {
      productApi.getProductDetails(`${uuid}`).then(({ data }) => {
        Promise.all(data.variation.map(async (vrtn) => {
          try {
            return {
              ...vrtn,
              name: vrtn.variations,
              stock: Number(vrtn.stock),
              front_thumbnail: vrtn.front_thumbnail.slice(0, 5) !== 'https' ? await FileHelper.getUrl(vrtn.front_thumbnail) : vrtn.front_thumbnail,
              back_thumbnail: vrtn.back_thumbnail.slice(0, 5) !== 'https' ? await FileHelper.getUrl(vrtn.back_thumbnail) : vrtn.back_thumbnail,
              sample_thumbnail: Boolean(vrtn.sample_thumbnail) ? vrtn.sample_thumbnail.slice(0, 5) !== 'https' ? await FileHelper.getUrl(vrtn.sample_thumbnail) : vrtn.sample_thumbnail : '',
            }
          } catch (err) {
            return {
              ...vrtn,
              name: vrtn.variations,
              stock: Number(vrtn.stock),
              front_thumbnail: undefined,
              back_thumbnail: undefined,
            }
          }
        })).then(variations => setInitialValues({
          name: data.card_name,
          category: data.category,
          sub_category: data.sub_category,
          source: Number(data.source),
          variations,
          price: Number(data.price),
          discount_price: Number(data.discount_price),
          description: data.description,
          is_popular: Number(data.is_popular),
          front_thumbnail: data.variation.length > 0 ? data.variation[0].front_thumbnail : undefined,
          back_thumbnail: data.variation.length > 0 ? data.variation[0].back_thumbnail : undefined,
        }));
        // setInitialValues(initVal);
      });
    } else {
      setInitialValues({
        name: '',
        category: '',
        sub_category: '',
        source: 1,
        variations: [
          {
            name: '',
            stock: 0,
            front_thumbnail: '',
            back_thumbnail: '',
          },
        ],
        price: 0,
        discount_price: 0,
        description: '',
        is_popular: 0,
        front_thumbnail: undefined,
        back_thumbnail: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return initialValues && <Formik
    initialValues={initialValues}
    validate={values => {
      const errors: any = {};

      // Validate name
      if (values.name === '') {
        errors.name = "Required"
      }

      // Validate variations
      // errors.variations = values.variations.map(variation => {
      //   const err = {};

      //   if (variation.name === '') err['name'] = 'Name required';
      //   if (variation.front_thumbnail === '') err['front_thumbnail'] = 'Front thumbnail required';
      //   if (variation.back_thumbnail === '') err['back_thumbnail'] = 'Back thumbnail required';

      //   return err;
      // });

      return errors;
    }}
    onSubmit={async (values, { setSubmitting }) => {
      const { source, variations, ...others } = values;
      const user = JSON.parse(localStorage.getItem('user') ?? '{}');

      // If editing
      if (Boolean(uuid)) {
        const requestBody = {
          ...others,
          uuid,
          source: `${source}`,
        };

        if (typeof variations[0].front_thumbnail !== 'string') {
          requestBody['front_thumbnail'] = await FileHelper.uploadFile(
            `user/${user.access_token_payload.uuid}/product/thumbnail/${uuidv4()}_${variations[0].front_thumbnail.name}`,
            variations[0].front_thumbnail
          );
        }

        if (typeof variations[0].back_thumbnail !== 'string') {
          requestBody['back_thumbnail'] = await FileHelper.uploadFile(
            `user/${user.access_token_payload.uuid}/product/thumbnail/${uuidv4()}_${variations[0].back_thumbnail.name}`,
            variations[0].back_thumbnail
          );
        }

        productApi.editProduct({ requestBody })
          .then(response => {
            // Edit product variation
            variations.forEach(async variation => {
              const requestBody = {
                product_uuid: `${uuid}`,
                variation_uuid: variation.uuid,
                category: others.category,
                variation_name: variation.name,
                stock: `${variation.stock}`,
                // sample_thumbnail: variation.sample_thumbnail,
                sample_view_side: variation.sample_view_side,
              };

              if (typeof variation.front_thumbnail !== 'string') {
                requestBody['front_thumbnail'] = await FileHelper.uploadFile(
                  `user/${user.access_token_payload.uuid}/product/thumbnail/${uuidv4()}_${variation.front_thumbnail.name}`,
                  variation.front_thumbnail
                );
              }

              if (typeof variation.back_thumbnail !== 'string') {
                requestBody['back_thumbnail'] = await FileHelper.uploadFile(
                  `user/${user.access_token_payload.uuid}/product/thumbnail/${uuidv4()}_${variation.back_thumbnail.name}`,
                  variation.back_thumbnail
                );
              }

              if (typeof variation.sample_thumbnail !== 'string') {
                requestBody['sample_thumbnail'] = await FileHelper.uploadFile(
                  `user/${user.access_token_payload.uuid}/product/thumbnail/${uuidv4()}_${variation.sample_thumbnail.name}`,
                  variation.sample_thumbnail
                );
              }

              productApi.editProductVariation({ requestBody });
            });

            // Delete variations with the UUID found in deletedVariationUUIDs
            deletedVariationUUIDs.forEach(productApi.deleteProductVariation);
          })
          .then(() => navigate('/portal/products'))
          .finally(() => setSubmitting(false));
      } else {
        const requestBody = {
          ...others,
          front_thumbnail: variations[0].front_thumbnail,
          back_thumbnail: variations[0].back_thumbnail,
          source: `${source}`,
        };

        if (typeof variations[0].front_thumbnail !== 'string') {
          requestBody['front_thumbnail'] = await FileHelper.uploadFile(
            `user/${user.access_token_payload.uuid}/product/thumbnail/${uuidv4()}_${variations[0].front_thumbnail.name}`,
            variations[0].front_thumbnail
          );
        }

        if (typeof variations[0].back_thumbnail !== 'string') {
          requestBody['back_thumbnail'] = await FileHelper.uploadFile(
            `user/${user.access_token_payload.uuid}/product/thumbnail/${uuidv4()}_${variations[0].back_thumbnail.name}`,
            variations[0].back_thumbnail
          );
        }

        productApi.addProduct({ requestBody })
          .then(response => {
            const { uuid } = response.data;

            variations.map(async variation => {
              const requestBody = {
                product_uuid: uuid,
                category: others.category,
                variation_name: variation.name,
                stock: `${variation.stock}`,
                front_thumbnail: variation.front_thumbnail,
                back_thumbnail: variation.back_thumbnail,
              };

              if (typeof variation.front_thumbnail !== 'string') {
                requestBody['front_thumbnail'] = await FileHelper.uploadFile(
                  `user/${user.access_token_payload.uuid}/product/thumbnail/${uuidv4()}_${variation.front_thumbnail.name}`,
                  variation.front_thumbnail
                );
              }

              if (typeof variation.back_thumbnail !== 'string') {
                requestBody['back_thumbnail'] = await FileHelper.uploadFile(
                  `user/${user.access_token_payload.uuid}/product/thumbnail/${uuidv4()}_${variation.back_thumbnail.name}`,
                  variation.back_thumbnail
                );
              }

              productApi.addProductVariation({ requestBody });
            });
          })
          .then(() => navigate('/portal/products'))
          .finally(() => setSubmitting(false));
        setSubmitting(false);
      }
    }}
  >
    {({
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      validateForm,
      setFieldValue,
    }) => (
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Typography sx={{ fontSize: '31px' }}>Create Product</Typography>
        <Box>
          <Typography component='label' htmlFor='name' className='required' variant='h5'>
            Product Name
          </Typography>
          <TextField
            error={Boolean(errors.name)}
            color='secondary'
            margin="dense"
            required
            fullWidth
            id="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Typography component='label' className='validation-error' variant='h5'>
            {errors.name?.toString()}
          </Typography>
        </Box>
        <Box>
          <Typography component='label' htmlFor='category' className='required' variant='h5'>
            Category
          </Typography>
          <TextField
            error={Boolean(errors.category)}
            color='secondary'
            margin="dense"
            required
            fullWidth
            id="category"
            value={values.category}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Typography component='label' className='validation-error' variant='h5'>
            {errors.category?.toString()}
          </Typography>
        </Box>
        <Box>
          <Typography component='label' htmlFor='sub_category' className='required' variant='h5'>
            Subcategory
          </Typography>
          <TextField
            error={Boolean(errors.sub_category)}
            color='secondary'
            margin="dense"
            required
            fullWidth
            id="sub_category"
            value={values.sub_category}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Typography component='label' className='validation-error' variant='h5'>
            {errors.sub_category?.toString()}
          </Typography>
        </Box>
        <Box>
          <Typography component='label' htmlFor='source' className='required' variant='h5'>
            Product Source
          </Typography>
          <TextField
            error={Boolean(errors.source)}
            color='secondary'
            margin="dense"
            required
            fullWidth
            id="source"
            value={values.source}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Typography component='label' className='validation-error' variant='h5'>
            {errors.source?.toString()}
          </Typography>
        </Box>
        <Box>
          <Typography component='label' htmlFor='price' className='required' variant='h5'>
            Price
          </Typography>
          <TextField
            error={Boolean(errors.price)}
            color='secondary'
            margin="dense"
            required
            fullWidth
            id="price"
            value={values.price}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Typography component='label' className='validation-error' variant='h5'>
            {errors.price?.toString()}
          </Typography>
        </Box>
        <Box>
          <Typography component='label' htmlFor='discount_price' className='required' variant='h5'>
            Discounted Price
          </Typography>
          <TextField
            error={Boolean(errors.discount_price)}
            color='secondary'
            margin="dense"
            required
            fullWidth
            id="discount_price"
            value={values.discount_price}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Typography component='label' className='validation-error' variant='h5'>
            {errors.price?.toString()}
          </Typography>
        </Box>
        <Box>
          <Typography component='label' htmlFor='description' className='required' variant='h5'>
            Description
          </Typography>
          <TextField
            error={Boolean(errors.description)}
            color='secondary'
            margin="dense"
            required
            fullWidth
            multiline
            rows={4}
            id="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Typography component='label' className='validation-error' variant='h5'>
            {errors.description?.toString()}
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: '20px',
          p: '10px 20px',
          bgcolor: '#D8E6FF',
          borderRadius: '4px',
        }}>
          <Typography>Hot pick</Typography>
          <CustomSwitch
            name="is_popular"
            checked={values.is_popular}
            onChange={event => setFieldValue('is_popular', event.target.checked)}
          />
        </Box>
        {values.variations.map((variation: any, i: number) => <Box key={i} sx={{ bgcolor: '#D8E3FF', my: '20px', p: '20px', borderRadius: '4px' }}>
          <Box sx={{ display: 'flex', alignItems: 'start' }}>
            <Box sx={{ flex: 5 }}>
              <Typography component='label' htmlFor='variation_name' className='required' variant='h5'>
                Variation Name
              </Typography>
              <TextField
                color='secondary'
                margin="dense"
                required
                fullWidth
                id="variation_name"
                value={variation.name}
                onChange={e => setFieldValue(`variations[${i}].name`, e.target.value)}
              // onBlur={handleBlur}
              />
              {/* <Typography component='label' className='validation-error' variant='h5'>
                {((errors.variations ?? []) as any[]).filter((err, index) => index === 1)}
              </Typography> */}
              <Typography component='label' htmlFor='stock' className='required' variant='h5'>
                Stock
              </Typography>
              <TextField
                color='secondary'
                margin="dense"
                required
                fullWidth
                id="stock"
                value={variation.stock}
                onChange={e => setFieldValue(`variations[${i}].stock`, e.target.value)}
              // onBlur={handleBlur}
              />
            </Box>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={e => handleDelete(values.variations, i, setFieldValue)}>
                <img src={icons.delete_icon} alt="trash can" />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: '20px' }}>
            <Box>
              <Typography component='label' htmlFor='front_thumbnail' variant='h5'>
                Front Thumbnail
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{
                  width: '250px',
                  height: '250px',
                  border: '1px solid #A7A7A7',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}>
                  {variation.front_thumbnail && <img
                    alt='Front thumbnail'
                    src={variation.front_thumbnail !== undefined ? typeof variation.front_thumbnail === 'string' ? variation.front_thumbnail : URL.createObjectURL(variation.front_thumbnail) : undefined}
                    style={{
                      maxWidth: '90%',
                      maxHeight: '90%',
                      borderRadius: '8px',
                      boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
                    }}
                  />}
                </Box>
                <Button
                  component="label"
                  sx={{
                    margin: 3,
                    height: '40px',
                    borderRadius: '30px',
                    color: 'black',
                    bgcolor: '#FECD20',
                  }}>
                  Upload image
                  <VisuallyHiddenInput name="front_thumbnail" type="file" onChange={(e: any) => {
                    setFieldValue(`variations[${i}].front_thumbnail`, e.target.files[0]);
                  }} accept="image/png, image/jpeg" />
                </Button>
              </div>
            </Box>
            <Box>
              <Typography component='label' htmlFor='back_thumbnail' variant='h5'>
                Back Thumbnail
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{
                  width: '250px',
                  height: '250px',
                  border: '1px solid #A7A7A7',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}>
                  {variation.back_thumbnail && <img
                    alt='Back thumbnail'
                    src={variation.back_thumbnail !== undefined ? typeof variation.back_thumbnail === 'string' ? variation.back_thumbnail : URL.createObjectURL(variation.back_thumbnail) : undefined}
                    style={{
                      maxWidth: '90%',
                      maxHeight: '90%',
                      borderRadius: '8px',
                      boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
                    }}
                  />}
                </Box>
                <Button
                  component="label"
                  variant="outlined"
                  sx={{
                    margin: 3,
                    height: '40px',
                    borderRadius: '30px',
                    color: 'black',
                    bgcolor: '#FECD20',
                  }}>
                  Upload image
                  <VisuallyHiddenInput name="back_thumbnail" type="file" onChange={(e: any) => {
                    setFieldValue(`variations[${i}].back_thumbnail`, e.target.files[0]);
                  }} accept="image/png, image/jpeg" />
                </Button>
              </div>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '20px' }}>
            <Box>
              <Typography component='label' htmlFor='sample_thumbnail' variant='h5'>
                Sample Thumbnail
              </Typography>
              <Box sx={{ display: 'flex', gap: '20px', mt: '8px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Box sx={{
                    width: '250px',
                    height: '250px',
                    border: '1px solid #A7A7A7',
                    borderRadius: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}>
                    {variation.sample_thumbnail && <img
                      alt='Sample thumbnail'
                      src={variation.sample_thumbnail !== undefined ? typeof variation.sample_thumbnail === 'string' ? variation.sample_thumbnail : URL.createObjectURL(variation.sample_thumbnail) : undefined}
                      style={{
                        maxWidth: '90%',
                        maxHeight: '90%',
                        borderRadius: '8px',
                        boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
                      }}
                    />}
                  </Box>
                  <Button
                    component="label"
                    variant="outlined"
                    sx={{
                      margin: 3,
                      height: '40px',
                      borderRadius: '30px',
                      color: 'black',
                      bgcolor: '#FECD20',
                    }}>
                    Upload image
                    <VisuallyHiddenInput name="sample_thumbnail" type="file" onChange={(e: any) => {
                      setFieldValue(`variations[${i}].sample_thumbnail`, e.target.files[0]);
                    }} accept="image/png, image/jpeg" />
                  </Button>
                </div>
              </Box>
            </Box>
            <Box>
              <Box>
                <Typography component='label' htmlFor='sample_view_side' variant='h5'>
                  Sample side
                </Typography>
                <Select
                  id="sample_view_side"
                  value={variation.sample_view_side}
                  sx={{ marginTop: '0.6rem', '&:focus': { border: 'solid #FECD20' } }}
                  fullWidth
                  // onChange={e => setSelectedProfileUUID(e.target.value)}
                  onChange={e => setFieldValue(`variations[${i}].sample_view_side`, e.target.value)}
                >
                  <MenuItem value={1}>Front</MenuItem>
                  <MenuItem value={2}>Back</MenuItem>
                </Select>
              </Box>
            </Box>
          </Box>
        </Box>)}
        {/* <Button
          sx={{
            margin: 3,
            height: '40px',
            borderRadius: '30px',
            color: 'black',
            bgcolor: '#FECD20',
          }}
          onClick={() => setFieldValue('variations', [
            ...values.variations,
            {
              name: '',
              stock: 0,
              front_thumbnail: '',
              back_thumbnail: '',
              sample_thumbnail: '',
            }
          ])}
        >
          Add Variation
        </Button> */}
        <LoadingButton
          id="signin-button"
          type="submit"
          fullWidth
          variant="contained"
          loading={isSubmitting}
          loadingPosition="start"
          startIcon={isSubmitting ? <div /> : undefined}
          sx={{ mt: 3, mb: 1, borderRadius: '30px', color: '#373736', bgcolor: '#FECD20' }}
        >
          <span>{isSubmitting ? 'Saving' : 'Continue'}</span>
        </LoadingButton>
      </Box>
    )
    }
  </Formik>
}