import React, { useEffect, useState } from "react";
import { Box, Typography, styled, Paper, Link, Button } from "@mui/material";
import FileHelper from "../../../lib/helpers/fileHelper";
import { Link as RouterLink, useParams } from "react-router-dom";
import adminApi from "../../../app/api/admin";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import NFCInvoice from "../component/nfcInvoice";

const baseUrl = 'https://member.pixelcard.co';

const DetailColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 'inherit',
  padding: '0 2rem',
});

const PDFoptions = {
  // default is `save`
  method: 'open',
  // default is Resolution.MEDIUM = 3, which should be enough, higher values
  // increases the image quality but also the size of the PDF, so be careful
  // using values higher than 10 when having multiple pages generated, it
  // might cause the page to crash or hang.
  resolution: Resolution.NORMAL,
  page: {
    // margin is in MM, default is Margin.NONE = 0
    margin: Margin.SMALL,
    // default is 'A4'
    format: 'letter',
    // default is 'portrait'
    orientation: 'portrait',
  },
  canvas: {
    // default is 'image/jpeg' for better size performance
    mimeType: 'image/png',
    qualityRatio: 1
  },
  // Customize any value passed to the jsPDF instance and html2canvas
  // function. You probably will not need this and things can break, 
  // so use with caution.
  overrides: {
    // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
    pdf: {
      compress: true
    },
    // see https://html2canvas.hertzen.com/configuration for more options
    canvas: {
      useCORS: true
    }
  },
};

export default function OrderDetailsComponent() {
  const { uuid } = useParams();
  const [order, setOrder] = useState<any>();
  const [orderProducts, setOrderProducts] = useState<any[]>();

  const getTargetElement = () => document.getElementById('invoice-template');

  const downloadPDF = () => {
    generatePDF(getTargetElement, PDFoptions);
  }

  useEffect(() => {
    adminApi.getOrderDetails(`${uuid}`).then(({ data }) => {
      setOrder(data);
      Promise.all(data.order_products.map(async (product) => {
        try {
          return {
            ...product,
            front_thumbnail: product.front_thumbnail.slice(0, 5) !== 'https' ? await FileHelper.getUrl(product.front_thumbnail) : product.front_thumbnail,
            back_thumbnail: product.back_thumbnail.slice(0, 5) !== 'https' ? await FileHelper.getUrl(product.back_thumbnail) : product.back_thumbnail,
            sample_thumbnail: Boolean(product.sample_thumbnail) ? product.sample_thumbnail.slice(0, 5) !== 'https' ? await FileHelper.getUrl(product.sample_thumbnail) : product.sample_thumbnail : '',
          }
        } catch (err) {
          return {
            ...product,
            front_thumbnail: undefined,
            back_thumbnail: undefined,
            sample_thumbnail: undefined,
          }
        }
      })).then(setOrderProducts);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem'
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography fontWeight='500' sx={{ flex: 1 }}>Order Detail {order?.order_ref_id}</Typography>
        {Number(order?.payment_status) === 3 && <Button
          variant="contained"
          color="primary"
          onClick={() => downloadPDF()}
        >
          Receipt
        </Button>}
      </Box>

      <Paper sx={{ p: '20px' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Customer Details</Typography>
        <Box sx={{
          display: 'flex',
          gap: '5px',
          flexWrap: 'nowrap',
          justifyContent: 'flex-start',
          py: '1rem',
        }}>
          <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
            <Typography variant="h4">Name</Typography>
            {Boolean(order?.customer.uuid) ?
              <Link
                component={RouterLink}
                to={{ pathname: `/portal/users/${order?.customer.uuid}` }}
                variant="body1"
              >
                {order?.customer.name}
              </Link>
              :
              <Typography variant="body1">{order?.customer.name}</Typography>
            }

          </DetailColumn>
          <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
            <Typography variant="h4">Referral Code</Typography>
            <Typography variant="body1">{order?.customer.referral_code}</Typography>
          </DetailColumn>
          <DetailColumn>
            <Typography variant="h4">Date</Typography>
            <Typography variant="body1">{order?.created_at}</Typography>
          </DetailColumn>
        </Box>
      </Paper>

      <Paper sx={{ p: '20px' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Shipping Info</Typography>
        <Box sx={{
          display: 'flex',
          gap: '5px',
          flexWrap: 'nowrap',
          justifyContent: 'flex-start',
          py: '1rem',
        }}>
          <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
            <Typography variant="h4">Name</Typography>
            <Typography variant="body1">{order?.customer.name}</Typography>
          </DetailColumn>
          <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
            <Typography variant="h4">Phone Number</Typography>
            <Typography variant="body1">{order?.order_payment?.bill_mobile}</Typography>
          </DetailColumn>
          <DetailColumn>
            <Typography variant="h4">Address</Typography>
            <Typography variant="body1">{order?.order_shipping?.address_1}, {order?.order_shipping?.address_2}</Typography>
          </DetailColumn>
        </Box>
      </Paper>

      <Paper sx={{ p: '20px' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Product Details</Typography>
        {(orderProducts ?? []).map((product, index) => <React.Fragment key={index}>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', my: '20px' }}>
            <img src={product.front_thumbnail} style={{ height: '175px', borderRadius: '8px' }} alt="front thumbnail" />
            <img src={product.back_thumbnail} style={{ height: '175px', borderRadius: '8px' }} alt="back thumbnail" />
          </Box>
          <Box key={index} sx={{
            display: 'flex',
            gap: '5px',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            py: '1rem',
          }}>
            <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
              <Typography variant="h4">Product Name</Typography>
              <Typography variant="body1">{product.card_name}</Typography>
            </DetailColumn>
            <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
              <Typography variant="h4">Name on Card</Typography>
              <Typography variant="body1">{product.name}</Typography>
            </DetailColumn>
            <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
              <Typography variant="h4">Position</Typography>
              <Typography variant="body1">{product.position}</Typography>
            </DetailColumn>
            {Boolean(product.serial_number) && <DetailColumn>
              <Typography variant="h4">Serial No.</Typography>
              <a href={`${baseUrl}/c/${product.serial_number}`}>
                <Typography variant="body1">{product.serial_number}</Typography>
              </a>
            </DetailColumn>}
          </Box>
        </React.Fragment>)}
      </Paper>
    </Box>
    <NFCInvoice order={order} />
  </Box>
}