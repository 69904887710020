import axios from "axios";

const baseURL = process.env.REACT_APP_ADMIN_HOST_URL;

interface AddCardData {
  user_uuid: string;
  img_url?: string;
  status?: number;
}

const addCard = (data: AddCardData): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  const headers = { 'Authorization': `Bearer ${user.access_token}` };

  return new Promise((resolve, reject) => {
    axios({ method: 'POST', url: "user/addCard", data, baseURL, headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default addCard;