import axios from "axios";

const baseURL = process.env.REACT_APP_ADMIN_HOST_URL;

interface AddOrderData {
  user_uuid: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  shipping_fee: number;
  items: any[];
}

const addOrder = (data: AddOrderData): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  const headers = { 'Authorization': `Bearer ${user.access_token}` };

  return new Promise((resolve, reject) => {
    axios({ method: 'POST', url: "order/add", data, baseURL, headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default addOrder;