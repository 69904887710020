import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Divider, Paper, styled, Typography } from "@mui/material";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import usersApi from "../../../app/api/admin";
import FileHelper from "../../../lib/helpers/fileHelper";
import icons from "../../../assets/icons";
import SubscriptionChip from "../components/SubscriptionChip";
import AddCardDialog from "../components/addCardDialog";
import ChangeSubscriptionDialog from "../components/changeSubscriptionDialog";
import images from "../../../assets/img";

const portalBaseUrl = process.env.REACT_APP_PORTAL_BASE_URL ?? 'https://member.pixelcard.co';

const DetailColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 'inherit',
  padding: '0 2rem',
});

export default function ViewUserComponent() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openAddCard, setOpenAddCard] = useState(false);
  const [user, setUser] = useState<any | undefined>();
  const [selectedCard, setSelectedCard] = useState<any | undefined>();

  const getUserDetails = () => {
    usersApi.getUserDetails(`${uuid}`).then(async (response) => {
      const user = response.data;
      let presigned_url = null;
      if (Boolean(user.image)) presigned_url = await FileHelper.getUrl(user.image);

      if (user.cards.length > 0) {
        Promise.all(user.cards.map(async (card) => {
          if (!Boolean(card.image) || card.image.slice(0, 5) === 'https') {
            return card;
          }

          return {
            ...card,
            image: await FileHelper.getUrl(card.image),
          }
        })).then(cardsData => setUser({
          ...user,
          cards: cardsData,
          image: presigned_url,
        }));
      } else {
        setUser({
          ...user,
          image: presigned_url,
        });
      }
    });
  }

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return Boolean(user) && <>
    <ChangeSubscriptionDialog open={open} selectedUser={user} handleClose={() => setOpen(false)} postSubmit={() => getUserDetails()} />
    <AddCardDialog open={openAddCard} selectedUser={user} handleClose={() => setOpenAddCard(false)} postSubmit={() => getUserDetails()} card={selectedCard} />
    <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
      <Avatar src={user.image ?? icons.profile_picture_icon} alt="" sx={{ width: '105px', height: '105px' }} />
      <Box sx={{ flex: 1 }}>
        <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '25px', fontWeight: 700 }}>{user.name}</Typography>
          <SubscriptionChip subscription_id={user.subscription_id} />
        </Box>
        <Typography sx={{ color: '#A7A7A7' }}>Date of birth: {user.birthday}</Typography>
      </Box>
      <Button
        variant="contained"
        onClick={() => {
          // navigate(`/portal/orders/add?user_uuid=${uuid}`);
          navigate({
            pathname: `/portal/orders/add`,
            search: createSearchParams({
              user_uuid: `${uuid}`,
            }).toString(),
          })
        }}
      >
        Add Order
      </Button>
    </Box>
    <Paper sx={{ p: '20px', my: '20px' }}>
      <Typography sx={{ fontSize: '25px', fontWeight: 500 }} gutterBottom>Account Details</Typography>

      <div style={{ marginBottom: '20px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ flex: 1, fontSize: '16px', fontWeight: 500 }}>Subscription Details</Typography>
          <Button
            sx={{ fontSize: '16px', fontWeight: 500 }}
            onClick={() => setOpen(true)}
          >
            Edit
          </Button>
        </Box>
        <Divider />
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>Subscription ID:</Typography>
          <Typography sx={{ flex: 1 }}>{user.subscription_id}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>Start Date:</Typography>
          <Typography sx={{ flex: 1 }}>{user.start_date}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>End Date:</Typography>
          <Typography sx={{ flex: 1 }}>{user.end_date}</Typography>
        </Box>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Personal Details</Typography>
        <Divider />
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>Email:</Typography>
          <Typography sx={{ flex: 1 }}>{user.email}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>Phone Number:</Typography>
          <Typography sx={{ flex: 1 }}>{user.phone_code}{user.phone_number}</Typography>
        </Box>
      </div>

      {Boolean(user.billing_address) && <div style={{ marginBottom: '20px' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Billing Details</Typography>
        <Divider />
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>Address 1:</Typography>
          <Typography sx={{ flex: 1 }}>{user.billing_address.address_1}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>Address 2:</Typography>
          <Typography sx={{ flex: 1 }}>{user.billing_address.address_2}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>City:</Typography>
          <Typography sx={{ flex: 1 }}>{user.billing_address.city}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>State:</Typography>
          <Typography sx={{ flex: 1 }}>{user.billing_address.state}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>Postal Code:</Typography>
          <Typography sx={{ flex: 1 }}>{user.billing_address.zip}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>Country:</Typography>
          <Typography sx={{ flex: 1 }}>{user.billing_address.country}</Typography>
        </Box>
      </div>}

      {Boolean(user.shipping_address) && <div style={{ marginBottom: '20px' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Shipping Details</Typography>
        <Divider />
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>Address 1:</Typography>
          <Typography sx={{ flex: 1 }}>{user.shipping_address.address_1}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>Address 2:</Typography>
          <Typography sx={{ flex: 1 }}>{user.shipping_address.address_2}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>City:</Typography>
          <Typography sx={{ flex: 1 }}>{user.shipping_address.city}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>State:</Typography>
          <Typography sx={{ flex: 1 }}>{user.shipping_address.state}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>Postal Code:</Typography>
          <Typography sx={{ flex: 1 }}>{user.shipping_address.zip}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ flex: 1 }}>Country:</Typography>
          <Typography sx={{ flex: 1 }}>{user.shipping_address.country}</Typography>
        </Box>
      </div>}
    </Paper>

    <Paper sx={{ p: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Cards</Typography>
        {/* <Button onClick={() => {
          setSelectedCard(undefined);
          setOpenAddCard(true);
        }}>+ Add Card</Button> */}
      </Box>
      {(user.cards ?? []).map((card, index) => <Box key={index}>
        <Box sx={{ my: '20px' }}>
          <img src={card.image ?? images.landing_page_default_image} style={{ height: '175px', borderRadius: '8px' }} alt="front thumbnail" />
        </Box>
        <Box sx={{
          display: 'flex',
          gap: '5px',
          flexWrap: 'nowrap',
          py: '1rem',
        }}>
          {Boolean(card.serial_number) && <DetailColumn>
            <Typography variant="h4">Serial No.</Typography>
            <a href={`${portalBaseUrl}/c/${card.serial_number}`}>
              <Typography variant="body1">{card.serial_number}</Typography>
            </a>
          </DetailColumn>}
          {Boolean(card.order_uuid) && <DetailColumn>
            <Typography variant="h4">Product ID</Typography>
            <a href={`/portal/orders/${card.order_uuid}`}>
              <Typography variant="body1">View Order</Typography>
            </a>
          </DetailColumn>}
          <div style={{ flex: 1 }} />
          <Button onClick={() => {
            setSelectedCard(card);
            setOpenAddCard(true);
          }}>Edit</Button>
        </Box>
        <hr />
      </Box>)}
    </Paper>
  </>
}