import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import icons from '../../../assets/icons';
import images from '../../../assets/img';
import basicAdminApi from '../../../app/api/basicadmin';

export default function SignInContainer() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.currentTarget);

    const body = {
      email: data.get('email'),
      password: data.get('password'),
      remember_me: data.get('remember') === 'remember',
    };

    await basicAdminApi.signin({
      requestBody: body,
    }).then((d) => {
        if (d === null) return;

        if (!d.success) {
          console.log('Incorrect credentials');
          return;
        }

        localStorage.setItem("user", JSON.stringify(d.data));
        navigate('/portal');
      })
      .catch(error => error)
      .finally(() => setIsLoading(false));

    return false;
  };

  return (
    <Box sx={{
      width: '100%',
      backgroundColor: '#FECD20',
    }}>
      <Container component="main" maxWidth="xs" style={{
        width: '336px',
        height: '100vh',
        display: 'grid',
        alignContent: 'center',
        justifyContent: 'center',
      }}>
        <CssBaseline />
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '10px',
          backgroundColor: '#3C3C3B',
          height: '50px',
          mb: 4
        }} >
          <Typography textAlign='center' style={{ color: '#fff', }}>
            Admin Portal
          </Typography>
        </Box>
        <Paper elevation={9}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1.3rem',
            borderRadius: '8.78px',
            width: "inherit"
          }}
        >
          <div style={{ margin: '20px' }}>
            <img src={images.pxlicon.toString()} style={{ maxWidth: '133px', maxHeight: "33" }} alt="Pixel Logo" />
          </div>
          <Typography component="h1" variant="h4">
            Log in to your account
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Typography component='label' className='required' variant='h5'>
              Email
            </Typography>
            <TextField
              color='secondary'
              margin="dense"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              placeholder='enter your email'
            />
            <Typography component='label' className='required' variant='h5'>
              Password
            </Typography>
            <TextField
              color='secondary'
              margin="dense"
              required
              fullWidth
              name="password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              placeholder='enter your password'
              InputProps={{
                endAdornment: <IconButton sx={{ opacity: 0.5 }} aria-label="show" size="small" onClick={() => setShowPassword(!showPassword)}>
                  <img src={showPassword ? icons.eye_close : icons.eye_open_icon} alt="eye_open_icon" />
                </IconButton>,
              }}
            />

            <Grid container>
              <Grid item xs>
                <FormControlLabel
                  name='remember'
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
              </Grid>
            </Grid>
            <LoadingButton
              color="secondary"
              id="signin-button"
              type="submit"
              fullWidth
              variant="contained"
              loading={isLoading}
              loadingPosition="start"
              startIcon={isLoading ? <div /> : undefined}
              sx={{ mt: 3, mb: 2, borderRadius: '30px' }}
            >
              <span>{isLoading ? 'Logging in' : 'Log In'}</span>
            </LoadingButton>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}
