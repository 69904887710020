import React, { useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, styled } from "@mui/material";

const StyledButton = styled(Button)({
  padding: '10px 35px',
  borderRadius: '30px',
  backgroundColor: '#373736',
  color: '#FECD20',
  '&:hover': {
    backgroundColor: '#252525',
    color: '#FECD20',
  }
});

function FilterDialog({ open, handleClose, search, setSearch }) {
  const [paymentStatus, setPaymentStatus] = useState<string>('');

  return <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: '25px', minWidth: '400px' } }}>
    <DialogTitle>Filter by payment status</DialogTitle>
    <DialogContent>
      <FormControl fullWidth sx={{ mt: '10px' }}>
        <InputLabel id="payment-status-label">Payment Status</InputLabel>
        <Select
          value={paymentStatus}
          label="Payment Status"
          labelId="payment-status-label"
          onChange={e => setPaymentStatus(e.target.value)}
        >
          <MenuItem value={''} selected>All</MenuItem>
          <MenuItem value={'0'}>Started</MenuItem>
          <MenuItem value={'1'}>Pending</MenuItem>
          <MenuItem value={'2'}>Processing</MenuItem>
          <MenuItem value={'3'}>Paid</MenuItem>
          <MenuItem value={'4'}>Failed</MenuItem>
        </Select>
      </FormControl>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <StyledButton onClick={() => {
          setSearch({
            ...search,
            payment_status: paymentStatus,
          });
          handleClose();
        }}>
          Filter
        </StyledButton>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={handleClose}>Cancel</Button>
      </div>
    </DialogContent>
  </Dialog>
};

export default FilterDialog;