import React, { useEffect, useState } from "react";
import OrderComponent from "./order.component";
import adminApi from "../../app/api/admin"

export default function OrderContainer() {
  const [search, setSearch] = useState({});
  const [orders, setOrders] = useState<any[]>([]);

  const handleCancel = (order_uuid: string) => {
    return adminApi.cancelOrder(order_uuid)
      .finally(() => adminApi.getOrders(search).then(response => setOrders(response.data)));
  }

  useEffect(() => {
    adminApi.getOrders(search).then(response => setOrders(response.data));
  }, [search]);

  return <OrderComponent
    orders={orders}
    handleCancel={handleCancel}
    setOrders={setOrders}
    search={search}
    setSearch={setSearch}
  />
}