import addProduct from "./addProduct.api";
import addProductVariation from "./addProductVariation.api";
import getProducts from "./getProductList.api";
import getProductDetails from "./getProductDetails.api";
import editProduct from "./editProduct.api";
import editProductVariation from "./editProductVariation.api";
import deleteProduct from "./deleteProduct.api";
import deleteProductVariation from "./deleteProductVariation.api";

const productApi = {
  addProduct,
  editProduct,
  deleteProduct,
  addProductVariation,
  editProductVariation,
  deleteProductVariation,
  getProducts,
  getProductDetails,
};

export default productApi;