import React, { useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, styled } from "@mui/material";

const StyledButton = styled(Button)({
  padding: '10px 35px',
  borderRadius: '30px',
  backgroundColor: '#373736',
  color: '#FECD20',
  '&:hover': {
    backgroundColor: '#252525',
    color: '#FECD20',
  }
});

function FilterDialog({ open, handleClose, search, setSearch }) {
  const [subscriptionId, setSubscriptionId] = useState<string>('');

  return <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: '25px', minWidth: '400px' } }}>
    <DialogTitle>Filter by plan</DialogTitle>
    <DialogContent>
      <FormControl fullWidth sx={{ mt: '10px' }}>
        <InputLabel id="subscription-plan-label">Subscription Plan</InputLabel>
        <Select
          value={subscriptionId}
          label="Subscription Plan"
          labelId="subscription-plan-label"
          onChange={e => setSubscriptionId(e.target.value)}
        >
          <MenuItem value={''} selected>All</MenuItem>
          <MenuItem value={'1'}>Free</MenuItem>
          <MenuItem value={'2'}>Lifestyle (Monthly)</MenuItem>
          <MenuItem value={'3'}>Lifestyle (Yearly)</MenuItem>
          <MenuItem value={'4'}>Lifestyle (Trial)</MenuItem>
          <MenuItem value={'5'}>Lifestyle (Lifetime)</MenuItem>
        </Select>
      </FormControl>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <StyledButton onClick={() => {
          setSearch({
            ...search,
            subscription_id: subscriptionId,
          });
          handleClose();
        }}>
          Filter
        </StyledButton>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={handleClose}>Cancel</Button>
      </div>
    </DialogContent>
  </Dialog>
};

export default FilterDialog;