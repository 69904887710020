import axios from "axios";
import moment from "moment-timezone"

const AxiosDefaultSetting = (props) => {
  
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.baseURL = process.env.REACT_APP_API_HOST_URL;
  axios.interceptors.request.use(function (config) {

    if (config.baseURL.includes(process.env.REACT_APP_API_HOST_URL)) {
      axios.defaults.headers.common["Timezone"] = moment.tz.guess();
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });
  return props.children;
}

export default AxiosDefaultSetting;