import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import icons from '../../../assets/icons'
import { useNavigate } from 'react-router-dom'

export default function SecondaryListItems({ callback , currentPath = '' }: 
{callback: (result: string) => void, currentPath: string}){
  const navigate = useNavigate()
  // const CustomListItemButton = ({ icon, callback, text, path, selected = false }:
  //   {icon: string, callback: (result: string) => void,text: string, path: string, selected: boolean }) => {
  
  //   return(
  //     <ListItemButton onClick={() => callback(path)} selected={selected}>
  //       <ListItemIcon>
  //       <img src={icon} alt="icon"/>
  //       </ListItemIcon>
  //       <ListItemText sx={{color:"#FFF"}} primary={text} />
  //     </ListItemButton>
  //   )
  // }
  
  const handleSignout = () =>{
    localStorage.removeItem("user");
    navigate('/signin')
  }
  return(
  <>
    {/* <CustomListItemButton icon={icons.support_icon.toString()} callback={callback} path={'/'} text={'Support'} selected={currentPath === '/portal/support'} /> */}
    {/* <CustomListItemButton icon={icons.logout_icon.toString()} callback={callback} path={'/'} text={'Logout'} selected={currentPath === '/portal/support'} /> */}
    <ListItemButton onClick={() => handleSignout()}>
        <ListItemIcon>
        <img src={icons.logout_icon.toString()} alt="logout_icon"/>
        </ListItemIcon>
        <ListItemText sx={{color:"#FFF"}} primary="Log out" />
      </ListItemButton>
    </>
  );
};