import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  breadcrumb: [{
    name: "dashboard",
    path: '/portal/dashboard'
  },{
    name: "all",
    path: null
  }
]
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setBreadcrumb: (state,action) => {
      state.breadcrumb = [];
      const { payload } = action;
      state.breadcrumb = payload.breadcrumb;
    }
  },
});

export const { setBreadcrumb } = adminSlice.actions;
export const adminReducer = adminSlice.reducer;