import empty_avatar from './empty_avatar.png'
import pixel_logo from './pixel_logo.svg'
import error_page from './error_page.svg'
import pxlicon from './pxlicon.png'
import landing_page_default_image from './landing_page_default_image.png'
import appointment_calendar from './appointment_calendar.svg'
import appointment_confirmed from './appointment_confirmed.svg'
const images = {
  appointment_calendar,
  appointment_confirmed,
  landing_page_default_image,
  pxlicon,
  error_page,
  pixel_logo,
  empty_avatar
} 

export default images