import axios from "axios";

interface Data{
  code: number,
  data:  null,
  message: string | null,
  success: boolean
}
const baseURL = process.env.REACT_APP_ADMIN_HOST_URL;
const signin = ({requestBody}): Promise<Data>  => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST', 
      url: baseURL+"/basicadmin/signin", 
      data: requestBody, 
      headers: { Authorization: null }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error.response.data));
})
};

export default signin;
