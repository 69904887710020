import React, { Dispatch, SetStateAction } from "react";
import { Box, Button, Checkbox, FormControlLabel, SxProps, Typography } from "@mui/material";

interface Subcategory {
  name: string;
  checked: boolean;
}

interface Category {
  name: string;
  subcategories: Subcategory[];
  checked?: boolean;
}

interface FilterCheckboxesProps {
  cats: Category[];
  setCats: Dispatch<SetStateAction<any[]>>;
  search: any;
  setSearch: Dispatch<SetStateAction<any>>;
  setShowDialog: Dispatch<SetStateAction<boolean>> | undefined;
}

const FilterCheckboxes = ({ cats, setCats, search, setSearch, setShowDialog }: FilterCheckboxesProps) => {
  const handleSubmit = event => {
    event.preventDefault();
    let categories: string[] = [];
    let subCategories: string[] = [];

    cats.forEach(cat => {
      if (cat.subcategories.length > 0) {
        if (cat.subcategories.filter(sc => sc.checked).length > 0) {
          categories.push(cat.name);
        }

        cat.subcategories.forEach(sc => {
          if (sc.checked) subCategories.push(sc.name);
        });
      } else if (cat.checked !== undefined) {
        if (cat.checked) {
          categories.push(cat.name);
        }
      }
    });

    const newSearch = {
      ...search,
      category: categories.join(','),
      sub_category: subCategories.join(','),
    };
    setSearch(newSearch);

    if (setShowDialog !== undefined) setShowDialog(false);
  }

  const additionalStyles: SxProps = setShowDialog === undefined ? {
    position: 'sticky',
    top: '100px',
    display: {
      xs: 'none',
      md: 'block',
    },
  } : {
    mx: 'auto',
    width: '100%',
  }

  return <Box sx={{ ...additionalStyles, bgcolor: 'white', borderRadius: '8px', border: 'solid 1px #C5C5C5', p: '20px' }}>
    <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>Card Type</Typography>
    <Box>
      {cats.map((cat, index) => (
        <div key={index}>
          <FormControlLabel
            key={index}
            label={cat.name}
            control={
              <Checkbox
                checked={cat.checked ?? cat.subcategories.filter(sc => sc.checked).length > 0}
                indeterminate={cat.subcategories.filter(sc => sc.checked).length > 0 && cat.subcategories.filter(sc => sc.checked).length < cat.subcategories.length}
                onChange={event => {
                  if (cat.subcategories.length > 0) {
                    setCats([
                      ...cats.slice(0, index),
                      {
                        ...cats[index],
                        subcategories: cats[index].subcategories.map(sc => ({
                          ...sc,
                          checked: event.target.checked,
                        })),
                      },
                      ...cats.slice(index + 1),
                    ]);
                  } else if (cat.checked !== undefined) {
                    setCats([
                      ...cats.slice(0, index),
                      {
                        ...cats[index],
                        checked: event.target.checked,
                      },
                      ...cats.slice(index + 1),
                    ])
                  }
                }}
              />
            }
          />
          {cat.subcategories.map((sc, i) => (
            sc.name &&
            <div style={{ marginLeft: '24px' }} key={`${index}_${i}`}>
              <FormControlLabel
                key={i}
                label={sc.name}
                control={
                  <Checkbox
                    checked={sc.checked}
                    onChange={event => setCats([
                      ...cats.slice(0, index),
                      {
                        ...cats[index],
                        subcategories: [
                          ...cats[index].subcategories.slice(0, i),
                          {
                            ...cats[index].subcategories[i],
                            checked: event.target.checked,
                          },
                          ...cats[index].subcategories.slice(i + 1),
                        ]
                      },
                      ...cats.slice(index + 1),
                    ])}
                  />
                }
              />
            </div>
          ))}
        </div>
      ))}
      <Button variant="contained" color="primary" sx={{ borderRadius: '30px', px: '20px', mt: '10px' }} onClick={handleSubmit}>
        Search
      </Button>
    </Box>
  </Box>
}

export default FilterCheckboxes;