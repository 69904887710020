import React, { useEffect, useState } from "react";
import ProductsComponent from "./products.component";
import productApi from "../../app/api/product";
import FileHelper from "../../lib/helpers/fileHelper";

export default function ProductsContainer() {
  const [search, setSearch] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [products, setProducts] = useState<any[]>([]);

  const handleChange = (event, index: number) => {
    const active = event.target.checked ? 1 : 0;
    const requestBody = {
      uuid: products[index].product_uuid,
      active,
    };
    productApi.editProduct({ requestBody })
      .then(() => {
        setProducts([
          ...products.slice(0, index),
          {
            ...products[index],
            active,
          },
          ...products.slice(index + 1),
        ]);
      });
  }

  const handleDelete = (uuid: string) => {
    return productApi.deleteProduct(uuid)
      .then(() => setProducts(products.filter(prod => prod.product_uuid !== uuid)));
  }

  useEffect(() => {
    productApi.getProducts(search).then(response => {
      Promise.all(response.data.map(async (product) => {
        try {
          return {
            ...product,
            card_thumbnail: product.card_thumbnail.slice(0, 5) !== 'https' ? await FileHelper.getUrl(product.card_thumbnail) : product.card_thumbnail,
          }
        } catch (err) {
          return {
            ...product,
            card_thumbnail: undefined,
          }
        }
      })).then(res => {
        setProducts(res);
        setIsLoaded(true);
      });
    });
  }, [search]);

  return isLoaded && <ProductsComponent
    products={products}
    handleChange={handleChange}
    handleDelete={handleDelete}
    search={search}
    setSearch={setSearch}
  />
}