import React, { Dispatch, MouseEvent, SetStateAction, useState } from "react";
import { Button, Box, Tab, Tabs, Typography, TextField, Menu, MenuItem, Dialog, IconButton, ListItemText } from "@mui/material";
import FormBox from "../../components/form-box/formBox";
import icons from "../../assets/icons";
import images from "../../assets/img";
import CustomSwitch from "../../components/customSwitch";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "./components/confirmationDialog";
import FilterCheckboxes from "./components/filterCheckboxes";

interface OrderComponentProps {
  products: any[];
  handleChange: (e: any, index: number) => void;
  handleDelete: (uuid: string) => any;
  search: any;
  setSearch: Dispatch<SetStateAction<any>>;
}

export default function ProductsComponent({ products, handleChange, handleDelete, search, setSearch }: OrderComponentProps) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [productName, setProductName] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);
  const catgrs = {};

  products.forEach(product => {
    if (catgrs[product.category] === undefined) catgrs[product.category] = [];
    catgrs[product.category].push(product.sub_category);
  });

  const [cats, setCats] = React.useState<any[]>(
    Object.keys(catgrs).map(cat => {
      const uniq_sc = [...new Set(catgrs[cat])];
      let category = {
        name: cat,
        subcategories: uniq_sc.map(sc => ({
          name: sc,
          checked: false,
        })),
      };

      // Set checked property only if it doesn't have subcategories
      if (uniq_sc.length === 0) {
        category['checked'] = false;
      }

      return category;
    })
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const openSortMenu = Boolean(anchorEl);

  return (
    <>
      <ConfirmationDialog
        open={open}
        selectedProduct={selectedProduct}
        handleClose={() => setOpen(false)}
        handleDelete={handleDelete}
      />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}>
        <Tabs value={selectedTab} onChange={handleTabChange} sx={{
          borderBottom: '1px solid #C5C5C5',
        }}>
          <Tab label="All" />
        </Tabs>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <Typography variant="h2">All List</Typography>
          <Button
            variant="contained"
            onClick={() => navigate('/portal/products/create')}
          >
            Create Product
          </Button>
        </Box>
        <Box
          sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
          component={'form'}
          onSubmit={(e) => {
            e.preventDefault();
            setSearch({
              ...search,
              search: productName,
            });
          }}
        >
          <TextField
            fullWidth
            size="small"
            type="text"
            placeholder="Search products by name"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{ borderRadius: '8px' }}
          >
            Search
          </Button>
          <Box>
            <Button
              startIcon={<img src={icons.filter_list} alt="filter" />}
              variant={Boolean(search.category) || Boolean(search.sub_category) ? "contained" : "outlined"}
              color={Boolean(search.category) || Boolean(search.sub_category) ? "primary" : "secondary"}
              sx={{ borderRadius: '8px' }}
              onClick={() => setShowDialog(true)}
            >Filters</Button>
            <Dialog
              open={showDialog}
              onClose={() => setShowDialog(false)}
              fullWidth
              maxWidth='md'
              PaperProps={{ sx: { p: '30px' } }}
            >
              <Box sx={{ display: 'flex', gap: '20px', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'flex-start' }}>
                <FilterCheckboxes
                  cats={cats}
                  setCats={setCats}
                  search={search}
                  setSearch={setSearch}
                  setShowDialog={setShowDialog}
                />
              </Box>
            </Dialog>
          </Box>
          <IconButton onClick={handleClick}>
            <img src={icons.arrow_up_down_round} alt="sort" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={openSortMenu}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              sx: { width: '160px' },
            }}
            slotProps={{
              paper: {
                sx: { borderRadius: '7px' },
              }
            }}
          >
            <MenuItem
              selected={search.order_by === 'created_at' && search.order === 'asc'}
              sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}
              onClick={() => {
                setSearch({
                  ...search,
                  order_by: 'created_at',
                  order: 'asc',
                });
                setAnchorEl(null);
              }}
            >
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Date (Asc)</ListItemText>
            </MenuItem>
            <MenuItem
              selected={search.order_by === 'created_at' && search.order === 'desc'}
              sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}
              onClick={() => {
                setSearch({
                  ...search,
                  order_by: 'created_at',
                  order: 'desc',
                });
                setAnchorEl(null);
              }}
            >
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Date (Desc)</ListItemText>
            </MenuItem>
            <MenuItem
              selected={search.order_by === 'name' && search.order === 'asc'}
              sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}
              onClick={() => {
                setSearch({
                  ...search,
                  order_by: 'name',
                  order: 'asc',
                });
                setAnchorEl(null);
              }}
            >
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Name (Asc)</ListItemText>
            </MenuItem>
            <MenuItem
              selected={search.order_by === 'name' && search.order === 'desc'}
              sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}
              onClick={() => {
                setSearch({
                  ...search,
                  order_by: 'name',
                  order: 'desc',
                });
                setAnchorEl(null);
              }}
            >
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Name (Desc)</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}>
          {products.map((product, index) => <FormBox key={index}>
            {/* <Typography fontWeight="500">Product ID: {product.order_uuid}</Typography> */}
            <Box sx={{
              display: 'flex',
              gap: '1rem',
              flexWrap: 'nowrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              py: '1rem',
            }}>
              <img alt="card preview" width={176} style={{ borderRadius: '8px' }} src={product.card_thumbnail ?? images.landing_page_default_image} />
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRight: '1px solid #C5C5C5',
                width: 'inherit',
                pr: '2rem',
              }}>
                <Typography variant="h4">Card Name</Typography>
                <Typography variant="body1">{product.card_name}</Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRight: '1px solid #C5C5C5',
                width: 'inherit',
                pr: '2rem',
              }}>
                <Typography variant="h4">Category</Typography>
                <Typography variant="body1">{product.category}</Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRight: '1px solid #C5C5C5',
                width: 'inherit',
                pr: '2rem',
              }}>
                <Typography variant="h4">Price</Typography>
                <Typography variant="body1">{product.currency}{product.price}</Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRight: '1px solid #C5C5C5',
                width: 'inherit',
                pr: '2rem',
              }}>
                <Typography variant="h4">Card Type</Typography>
                <Typography variant="body1">{Number(product.type) === 1 ? 'Template' : 'Custom'}</Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRight: '1px solid #C5C5C5',
                width: 'inherit',
                pr: '1rem',
              }}>
                <Typography variant="h4">Published</Typography>
                <CustomSwitch checked={Boolean(Number(product.active))} onChange={e => handleChange(e, index)} />
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    setSelectedProduct(product);
                    setMoreAnchorEl(e.currentTarget);
                  }}
                  endIcon={<img src={icons.arrow_down_01_icon} alt="upload" />}
                >
                  More Actions
                </Button>
              </Box>
            </Box>
          </FormBox>)}
          <Menu
            anchorEl={moreAnchorEl}
            open={Boolean(moreAnchorEl)}
            onClose={() => setMoreAnchorEl(null)}
          >
            <MenuItem onClick={() => {
              navigate(`/portal/products/${selectedProduct.product_uuid}/edit`);
              setMoreAnchorEl(null);
            }} sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
              Edit Product
            </MenuItem>
            <MenuItem onClick={() => {
              setOpen(true);
              setMoreAnchorEl(null);
            }} sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
              Delete Product
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </>);
}