import React from "react";
import DrawerComponent from "./drawer.component";

function DrawerContainer({toggleDrawer,open}:{toggleDrawer:any,open:boolean}){
  return <DrawerComponent
  toggleDrawer={toggleDrawer}
  open={open}
  />
}

export default DrawerContainer;