import axios from "axios";

const deleteProductVariation = (uuid: string): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.post("product/deleteVariation", { uuid })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default deleteProductVariation;